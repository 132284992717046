import { Add, Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";

import ControlledFileInput from "../../../ui/components/DragAndDropFileInput/ControlledFileInput";

import Form from "../../../ui/components/Form";
import { useUploadAttachment } from "../api/uploadAttachment";
import TagsInput from "../../../ui/components/TagsInput";

export function UploadAttachment({ accept = "", onSuccess = () => {} }) {
  const fileSchema = yup.object().shape({
    name: yup
      .string()
      .required("O arquivo deve ter um nome")
      // .max(50, "Nome do arquivo muito grande, máximo 50 caracteres")
      .test("file_extension_validation", (value, options) => {
        if (
          accept.split(",").some((fileExtension) => {
            return value.includes(fileExtension);
          })
        ) {
          return true;
        }
        return options.createError({
          message: `Tipos de arquivos permitidos: ${accept}`,
          path: "files", // Fieldname
        });
      }),
    size: yup.number().max(80000000, "O tamanho máximo do arquivo é 80MB"),
  });

  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    // name: yup.string(),
    files: yup
      .array()
      .of(fileSchema)
      .min(1, "Escolha um arquivo")
      .max(1, "Escolha apenas um arquivo"),
    tags: yup.array(),
  });

  const defaultValues = {
    name: "",
    tags: [],
    files: [],
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const isDisplaySmall = useMediaQuery(theme.breakpoints.down("md"));
  const uploadAttachment = useUploadAttachment();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = (values) => {
    uploadAttachment.mutate(
      {
        name: values.name,
        file: values.files[0].file,
        tags: values.tags.map((tag) => tag.name),
      },
      {
        onSuccess: () => {
          handleCloseDialog();
          onSuccess();
        },
      }
    );
  };

  return (
    <>
      <Button
        sx={{ fontSize: "5px", mr: 1 }}
        onClick={handleOpenDialog}
        endIcon={<Add />}
        variant="contained"
      >
        Subir
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullScreen={isDisplaySmall}
      >
        <DialogTitle>Adicionar arquivos</DialogTitle>
        <IconButton
          onClick={handleCloseDialog}
          sx={{ position: "absolute", top: 3, right: 3 }}
        >
          <Close />
        </IconButton>
        <DialogContent
          sx={{
            // width: "600px",
            form: {
              display: "flex",
              flexDirection: "column",
              gap: 2,
              "&>:first-child": { marginBottom: 5 },
            },
          }}
        >
          {uploadAttachment.isLoading ? (
            <CircularProgress />
          ) : (
            <Form
              defaultValues={defaultValues}
              schema={schema}
              onSubmit={handleSubmit}
            >
              {({ register, control, formState }) => {
                return (
                  <>
                    <ControlledFileInput
                      // multiple
                      accept={accept}
                      name="files"
                      control={control}
                      helperText={
                        formState.errors["files"]?.message ||
                        formState.errors["files"]?.[0].name?.message ||
                        formState.errors["files"]?.[0].size?.message ||
                        "*Tamanho máximo de arquivo: 80MB"
                      }
                      error={!!formState.errors["files"]}
                    />
                    <FormControl name="name" {...register("name")}>
                      <InputLabel shrink htmlFor="name-field">
                        Nome
                      </InputLabel>
                      <Input
                        {...register("name")}
                        label="Nome"
                        variant="standard"
                        id="name-field"
                      />
                      <FormHelperText error={!!formState.errors["name"]}>
                        {formState.errors["name"]?.message}
                      </FormHelperText>
                    </FormControl>
                    <TagsInput name="tags" control={control} />

                    <Button type="submit">Subir</Button>
                  </>
                );
              }}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
