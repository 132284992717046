import { Container } from "@mui/material";
import ImageContent from "./ImageContent";
import ImageInput from "./ImageInput";

function ImageComponent({
  contentId,
  value,
  onChange,
  isEditing = false,
  canBeMandatory = false,
}) {
  function setMandatory(index, newMandatory) {
    const newValue = [...value];

    newValue[index].mandatory = newMandatory;

    if (newValue[index].isValid) {
      newValue[index].modified = true;
    }

    onChange([...newValue]);
  }

  return (
    <Container
      disableGutters
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {isEditing && <ImageInput value={value} onChange={onChange} />}
      {!!value && (
        <ImageContent
          contentId={contentId}
          value={value}
          isEditing={isEditing}
          setMandatory={setMandatory}
          canBeMandatory={canBeMandatory}
        />
      )}
    </Container>
  );
}

export default ImageComponent;
