import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const deleteVimeoVideo = async (videoId) => {
  const res = await axios.cadernoVirtualBase.delete(
    `/server/api/vimeo/delete/${videoId}`
  );

  return res;
};

export const useDeleteVimeoVideo = ({ config, videoId } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: deleteVimeoVideo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["vimeoVideos"] });
    },
  });
};
