import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useDeleteVimeoVideo } from "../api/deleteVimeoVideo";
import { LoadingButton } from "@mui/lab";
import { Close, Delete } from "@mui/icons-material";
import { useState } from "react";

export default function DeleteVimeoVideo({ videoId }) {
  const { mutate, isLoading } = useDeleteVimeoVideo();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleConfirm = () => {
    mutate(videoId);
    handleCloseDialog();
  };

  return (
    <>
      <LoadingButton
        loading={isLoading}
        disabled={!videoId || true}
        onClick={handleOpenDialog}
        startIcon={<Delete />}
      >
        Deletar
      </LoadingButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <IconButton sx={{ position: "absolute", top: 3, right: 3 }}>
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Esse vídeo será deletado permanentemente, essa ação não é
            reversível. <br />
            Tem certeza que deseja deletar esse vídeo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!videoId}
            onClick={handleConfirm}
            loading={isLoading}
            startIcon={<Delete />}
          >
            Deletar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
