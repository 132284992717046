import { Outlet, Route } from "react-router-dom";
import { lazy, Suspense } from "react";

import RouterBuilder, { idKey, fatherKey } from "./RouterBuilder";

import ProtectedRoutesLayout from "../../ui/blocks/ProtectedRoutesLayout";
// import TrackListPage from "../../ui/pages/TrackListPage";
// import CertificatePage from "../../ui/pages/CertificatePage";
// import SocialThemesPage from "../../ui/pages/SocialThemesPage";
// import ChannelsPage from "../../ui/pages/ChannelsPage";
// import NewsPage from "../../ui/pages/News/NewsPage";
// import NewsPost from "../../ui/pages/News/NewsPost";
// import SocialPostsPage from "../../ui/pages/SocialPostsPage";
// import NewSocialPostPage from "../../ui/pages/SocialPostsPage/pages/NewPostPage";
// import SocialPostPage from "../../ui/pages/SocialPostPage";
// import ChannelPosts from "../../ui/pages/ChannelPosts";
// import NewChannelPostPage from "../../ui/pages/ChannelPosts/pages/NewPostPage";
// import NewNewsPostPage from "../../ui/pages/News/NewsPost/pages/NewPostPage";

// import ChannelPost from "../../ui/pages/ChannelPost";
// import ExtraCoursePage from "../../ui/pages/ExtraCoursePage";
// import ExtraCourse from "../../ui/pages/ExtraCourse";
// import ExtraChapter from "../../ui/pages/ExtraChapter";
// import TutorialsPage from "../../ui/pages/TutorialsPage";
// import TutorialChapters from "../../ui/pages/TutorialChapters";
// import TutorialChapter from "../../ui/pages/TutorialChapter";
// import ClassDiary from "../../ui/pages/ClassDiary";
// import GameListPage from "../../ui/pages/Games/pages/GameListPage";
// import RegisterPresence from "../../ui/pages/RegisterPresence";

import renderTrackRoutes from "./trackRoutes";

import renderCourseRoutes from "./courseRoutes";

import renderUserRoutes from "./userRoutes";
import { EditSurvey } from "../../features/survey/routes/EditSurvey";
import { CreateSurvey } from "../../features/survey/routes/CreateSuryey";
import DirectAccessChapter from "../../features/chapters/components/DirectAccessChapter";

// import IdeaPage from "../../ui/pages/Idea/IdeaPage";

// import ContentIdea from "../../ui/pages/ContentIdea/ContentIdea";

// import AddNewIdea from "../../ui/pages/AddNewIdea/AddNewIdea";

// import ProposePage from "../../ui/pages/ProposePage/ProposePage";

// import DashboardPage from "../../ui/pages/Dashboard/DashboardPage";

// import MenuEducator from "../../ui/pages/MenuEducator";

// import ModuleSelect from "../../ui/blocks/ModuleSelect";

// import SearchPage from "../../ui/pages/Search/SearchPage";

// import LivesPages from "../../ui/pages/LivesPage";

// import OnDemandPage from "../../ui/pages/OnDemand";

// import Logout from "../../ui/pages/Logout";

// import LocalPage from "../../ui/pages/LocalPage";

// import SurveyPage from "../../ui/pages/SurveyPage";

// import Survey from "../../ui/pages/SurveyPage/Survey";

// import SocialPostPage from "../../ui/pages/SocialPostPage";

// import MeetPage from "../../ui/pages/MeetPage";

// import ManagerCataloguePage from "../../ui/pages/ManagerCataloguePage";

// import BaseConhecimento from "../../ui/pages/BaseConhecimento";

const TrackListPage = lazy(() => import("../../ui/pages/TrackListPage"));

const CertificatePage = lazy(() => import("../../ui/pages/CertificatePage"));

const SocialThemesPage = lazy(() => import("../../ui/pages/SocialThemesPage"));

const ChannelsPage = lazy(() => import("../../ui/pages/ChannelsPage"));

const NewsPage = lazy(() => import("../../ui/pages/News/NewsPage"));

const NewsPost = lazy(() => import("../../ui/pages/News/NewsPost"));

const SocialPostsPage = lazy(() => import("../../ui/pages/SocialPostsPage"));

const NewSocialPostPage = lazy(() =>
  import("../../ui/pages/SocialPostsPage/pages/NewPostPage")
);

const NewChannelPostPage = lazy(() =>
  import("../../ui/pages/ChannelPosts/pages/NewPostPage")
);

const NewNewsPostPage = lazy(() =>
  import("../../ui/pages/News/NewsPost/pages/NewPostPage")
);

const ChannelPost = lazy(() => import("../../ui/pages/ChannelPost"));

const ExtraCoursePage = lazy(() => import("../../ui/pages/ExtraCoursePage"));

const ExtraCourse = lazy(() => import("../../ui/pages/ExtraCourse"));

const ExtraChapter = lazy(() => import("../../ui/pages/ExtraChapter"));

const TutorialsPage = lazy(() => import("../../ui/pages/TutorialsPage"));

const TutorialChapters = lazy(() => import("../../ui/pages/TutorialChapters"));

const TutorialChapter = lazy(() => import("../../ui/pages/TutorialChapter"));

const ClassDiary = lazy(() => import("../../ui/pages/ClassDiary"));

const GameListPage = lazy(() =>
  import("../../ui/pages/Games/pages/GameListPage")
);

const RegisterPresence = lazy(() => import("../../ui/pages/RegisterPresence"));

// const renderTrackRoutes = lazy(() => import("./trackRoutes"));

// const renderCourseRoutes = lazy(() => import("./courseRoutes"));

// const renderUserRoutes = lazy(() => import("./userRoutes"));

const IdeaPage = lazy(() => import("../../ui/pages/Idea/IdeaPage"));

const ContentIdea = lazy(() =>
  import("../../ui/pages/ContentIdea/ContentIdea")
);

const AddNewIdea = lazy(() => import("../../ui/pages/AddNewIdea/AddNewIdea"));

const ProposePage = lazy(() =>
  import("../../ui/pages/ProposePage/ProposePage")
);

const DashboardPage = lazy(() =>
  import("../../ui/pages/Dashboard/DashboardPage")
);

const MenuEducator = lazy(() => import("../../ui/pages/MenuEducator"));

const ModuleSelect = lazy(() => import("../../ui/blocks/ModuleSelect"));

const SearchPage = lazy(() => import("../../ui/pages/Search/SearchPage"));

const LivesPages = lazy(() => import("../../ui/pages/LivesPage"));

const OnDemandPage = lazy(() => import("../../ui/pages/OnDemand"));

const Logout = lazy(() => import("../../ui/pages/Logout"));

const LocalPage = lazy(() => import("../../ui/pages/LocalPage"));

const SurveysListPage = lazy(() => import("../../ui/pages/SurveyPage"));

const SurveyPage = lazy(() => import("../../features/survey/pages/SurveyPage"));

const SocialPostPage = lazy(() => import("../../ui/pages/SocialPostPage"));

const MeetPage = lazy(() => import("../../ui/pages/MeetPage"));

const ManagerCataloguePage = lazy(() =>
  import("../../ui/pages/ManagerCataloguePage")
);
const BaseConhecimento = lazy(() => import("../../ui/pages/BaseConhecimento"));

const ChannelPosts = lazy(() => import("../../ui/pages/ChannelPosts"));

const AluraTest = lazy(() =>
  import("../../features/courses/routes/AluraCourse")
);

const CertificationsPage = lazy(() =>
  import("../../features/certifications/routes/Certifications")
);

// const routeFactory = RouterBuilder.routeFactory;

export const routes = [
  {
    [idKey]: "landing-page",
    index: true,
    Page: DashboardPage,
  },
  {
    [idKey]: "trilhas",
    path: "trilhas",
    Page: TrackListPage,
  },
  {
    [idKey]: "certificate",
    path: "certificate/:enrollmentId/:courseId",
    Page: CertificatePage,
  },
  {
    [idKey]: "social-newtwork",
    path: "postagens",
    Page: SocialThemesPage,
  },
  {
    [idKey]: "social-filtro",
    path: "postagens/filtro",
    Page: SocialThemesPage,
  },
  {
    [idKey]: "social-posts",
    path: "postagens/:socialid",
    Page: SocialPostsPage,
  },
  {
    [idKey]: "social-post",
    path: "postagens/:socialid/:postid",
    Page: SocialPostPage,
  },
  {
    [idKey]: "social-post-new",
    path: "postagens/:socialid/new-post",
    Page: NewSocialPostPage,
  },
  {
    [idKey]: "social-post-new",
    path: "postagens/:socialid/edit-post/:postid",
    Page: NewSocialPostPage,
  },
  {
    [idKey]: "channels",
    path: "canais",
    Page: ChannelsPage,
  },
  {
    [idKey]: "channels-filtro",
    path: "canais/filtro",
    Page: ChannelsPage,
  },
  {
    [idKey]: "channels-posts",
    path: "canais/:canalid",
    Page: ChannelPosts,
  },
  {
    [idKey]: "channel-new-post",
    path: "canais/:canalid/new-post",
    Page: NewChannelPostPage,
  },
  {
    [idKey]: "channel-new-post",
    path: "canais/:canalid/edit/:postId",
    Page: NewChannelPostPage,
  },
  {
    [idKey]: "channel-post",
    path: "canais/:canalid/:postid",
    Page: ChannelPost,
  },
  {
    [idKey]: "news-filter",
    path: "noticias/filtro",
    Page: NewsPage,
  },
  {
    [idKey]: "news",
    path: "noticias",
    Page: NewsPage,
  },
  {
    [idKey]: "news-post",
    path: "noticias/:noticiaid",
    Page: NewsPost,
  },
  {
    [idKey]: "news-new-post",
    path: "noticias/new-post",
    Page: NewNewsPostPage,
  },
  {
    [idKey]: "news-new-post",
    path: "noticias/edit/:postId/:name",
    Page: NewNewsPostPage,
  },
  {
    [idKey]: "extra-course",
    path: "extras",
    Page: ExtraCoursePage,
  },
  {
    [idKey]: "extra-course-filter",
    path: "extras/filtro",
    Page: ExtraCoursePage,
  },
  {
    [idKey]: "extra-course-curso",
    path: "extras/:disciplinaid",
    Page: ExtraCourse,
  },
  {
    [idKey]: "extra-course-capitulo",
    path: ":currentChapter",
    Page: ExtraChapter,
    [fatherKey]: "extra-course-curso",
  },
  {
    [idKey]: "tutoriais",
    path: "tutoriais",
    Page: TutorialsPage,
  },
  {
    [idKey]: "lives",
    path: "lives",
    Page: LivesPages,
  },
  {
    [idKey]: "lives-filtro",
    path: "lives/filtro",
    Page: LivesPages,
  },
  {
    [idKey]: "lives-post",
    path: "lives/:id",
    Page: LivesPages,
  },
  {
    [idKey]: "tutoriais-filtro",
    path: "tutoriais/filtro",
    Page: TutorialsPage,
  },
  {
    [idKey]: "tutorial-capitulos",
    path: "tutoriais/:categoriaid",
    Page: TutorialChapters,
  },
  {
    [idKey]: "tutorial-capitulo",
    path: "tutoriais/:categoriaid/:cursoid",
    Page: TutorialChapter,
  },
  {
    [idKey]: "tutorial-capitulo",
    path: "tutoriais/:categoriaid/:cursoid/:capituloid",
    Page: TutorialChapter,
  },
  {
    [idKey]: "catalogo",
    path: "catalogo",
    Page: OnDemandPage,
  },
  {
    [idKey]: "catalogo-filtrado",
    path: "catalogo/:cursoid",
    Page: OnDemandPage,
  },
  {
    [idKey]: "catalogo-multi-filtrado",
    path: "catalogo/filtro",
    Page: OnDemandPage,
  },
  {
    [idKey]: "pesquisa",
    path: "pesquisa",
    Page: SurveysListPage,
  },
  {
    [idKey]: "responder-pesquisa",
    path: "pesquisa/:id",
    Page: SurveyPage,
  },
  {
    [idKey]: "diario-de-classe",
    path: "diario/:type/:disciplinaid",
    Page: ClassDiary,
  },
  {
    [idKey]: "diario-de-classe-id",
    path: "diario/:type/:disciplinaid/:id",
    Page: ClassDiary,
  },
  {
    [idKey]: "games",
    path: "game",
    Page: GameListPage,
  },
  {
    [idKey]: "games-filtro",
    path: "game/filtro",
    Page: GameListPage,
  },
  {
    [idKey]: "validar-presenca",
    path: "presenca/:presencaid",
    Page: RegisterPresence,
  },
  {
    [idKey]: "ideias",
    path: "ideias",
    Page: IdeaPage,
  },
  {
    [idKey]: "ideias-filtro",
    path: "ideias/filtro",
    Page: IdeaPage,
  },
  {
    [idKey]: "content-ideias",
    path: "ideias/:id",
    Page: ContentIdea,
  },
  {
    [idKey]: "content-propose",
    path: "ideias/:id/proposta/nova",
    Page: AddNewIdea,
  },
  {
    [idKey]: "reaction-propose",
    path: "ideias/:id/proposta/:propostaid",
    Page: ProposePage,
  },
  {
    [idKey]: "module-select",
    path: "module",
    Page: ModuleSelect,
  },
  {
    [idKey]: "menu-educator",
    path: "menu-educador",
    Page: MenuEducator,
  },
  {
    [idKey]: "search",
    path: "search",
    Page: SearchPage,
  },
  {
    [idKey]: "logout",
    path: "logout",
    Page: Logout,
  },
  {
    [idKey]: "local",
    path: "local/:key",
    Page: LocalPage,
  },
  {
    [idKey]: "base",
    path: "base",
    Page: BaseConhecimento,
  },
  {
    [idKey]: "meet",
    path: "meet/:sessionId",
    Page: MeetPage,
  },
  {
    [idKey]: "managerCatalogue",
    path: "prateleira",
    Page: ManagerCataloguePage,
  },
  {
    [idKey]: "createSurvey",
    path: "pesquisa/criar",
    Page: CreateSurvey,
  },
  {
    [idKey]: "editSurvey",
    path: "pesquisa/editar/:surveyId",
    Page: EditSurvey,
  },
  {
    [idKey]: "aluraCourse",
    path: "alura/:type/:courseId/:title",
    Page: AluraTest,
  },
  {
    [idKey]: "certifications",
    path: "certificacoes",
    Page: CertificationsPage,
  },
];

const ProtectedRoutes = new RouterBuilder(routes).nestedRoutes;

const renderProtectedRoutes = (
  <Route path="/" element={<ProtectedRoutesLayout />}>
    {renderTrackRoutes}
    {renderCourseRoutes}
    {renderUserRoutes}
    {ProtectedRoutes}
  </Route>
);

export default renderProtectedRoutes;
