import { api } from "../../../../shared/api";
import { finalizaCapitulo } from "../../ChapterPage/common/redux/chapterReducer";
import store from "../../../../shared/store/index";

const { dispatch } = store;
window.API = {};

class ScormScript {
  constructor(alunoid, capituloid, disciplinaid, matriculaid, token) {
    this.alunoid = alunoid;
    this.capituloid = capituloid;
    this.disciplinaid = disciplinaid;
    this.matriculaid = matriculaid;
    this.token = token;
    this.lastError = 0;
    this.valorExit = "";
    this.scormData = {};
    this.defaultUrlParams = `capituloid=${capituloid}&matriculaid=${matriculaid}`;
    this.token = token;
    // this.finalizaCapitulo = async () => {
    //   const body = new FormData();
    //   body.append(
    //     "dados",
    //     `[{"alunoid":${alunoid},"matriculaid":${matriculaid},"disciplinaid":${disciplinaid},"capituloid":${capituloid}}]`
    //   );
    //   try {
    //     const res = await fetch(api.finaliza_capitulo, {
    //       method: "post",
    //       headers: { token: token },
    //       body,
    //     });
    //     if (res.status < 200 && res.status >= 300) {
    //       throw new Error(res.statusText);
    //     }
    //   } catch (error) {
    //     console.log("scorm/finalizaCapitlo error", error);
    //   }
    // };
  }

  finalizaCapitulo2 = async () => {
    // const body = new FormData();
    // body.append(
    //   "dados",
    //   `[{"alunoid":${this.alunoid},"matriculaid":${this.matriculaid},"disciplinaid":${this.disciplinaid},"capituloid":${this.capituloid}}]`
    // );
    try {
      dispatch(finalizaCapitulo({ ...this }));
      // const res = await fetch(api.finaliza_capitulo, {
      //   method: "post",
      //   headers: { token: this.token },
      //   body,
      // });
      // if (res.status < 200 && res.status >= 300) {
      //   throw new Error(res.statusText);
      // }
    } catch (error) {
      console.log("scorm/finalizaCapitlo error", error);
    }
  };

  loadData = () => {
    return new Promise((resolve) => {
      let params = [
        "cmi.launch_data",
        "cmi.core.lesson_status",
        "cmi.core.score.raw",
        "cmi.suspend_data",
        "cmi.core.student_id",
        "cmi.core.student_name",
        "cmi.core.credit",
        "cmi.core.entry",
        "cmi.core.total_time",
        "cmi.core.lesson_location",
        "cmi.core.session_time",
      ];
      this.scormData["cmi.core._children"] =
        "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,exit,session_time";
      this.scormData["cmi.core.score._children"] = "raw";

      let count = 0;
      let getParam = (parametro) => {
        let query = `parametro=${parametro}&${this.defaultUrlParams}`;
        console.log(
          `%c begin PRELOAD "${parametro}"`,
          "color:black;background:yellow"
        );
        fetch(`${api.scorm_obtem_valor}?${query}`, {
          method: "get",
          headers: { token: this.token },
        })
          .then((res) => res.json())
          .then((json) => {
            const valor = json[0].data.valor || "";
            this.scormData[parametro] = valor;
            console.log(
              `%c end PRELOAD "${parametro}"`,
              "color:black;background:yellow",
              valor
            );
            count++;
            if (count < params.length) getParam(params[count]);
            else resolve();
          })
          .catch((err) => {
            console.log(
              `%c error PRELOAD "${parametro + " | " + err}"`,
              "color:white;background:red"
            );
            count++;
            if (count < params.length) getParam(params[count]);
            else resolve();
          });
      };
      getParam(params[count]);
    });
  };

  loadScormApi = () => {
    window.API.LMSInitialize = LMSInitialize;
    window.API.LMSFinish = LMSFinish;
    window.API.LMSGetValue = LMSGetValue;
    window.API.LMSSetValue = LMSSetValue;
    window.API.LMSCommit = LMSCommit;
    window.API.LMSGetLastError = LMSGetLastError;
    window.API.LMSGetDiagnostic = LMSGetDiagnostic;
    window.API.LMSGetErrorString = LMSGetErrorString;

    const _this = this;

    function LMSInitialize() {
      try {
        let query = _this.defaultUrlParams;
        console.log(
          "----------------------defaultUrlParams",
          _this.defaultUrlParams
        );
        console.log("----------------------query", query);
        console.log(`${api.scorm_iniciar}?${query}`);
        console.log("begin INITIALIZE");
        fetch(`${api.scorm_iniciar}?${query}`, {
          method: "get",
          headers: { token: _this.token },
        });
      } catch (err) {
        console.log(err);
      }

      return "true";
    }

    function LMSGetValue(parametro) {
      switch (parametro) {
        case "cmi.core._children":
          return "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,exit,session_time";
        case "cmi.core.score._children":
          return "raw";
        case "cmi.core.exit":
          _this.lastError = "404";
          return "";
        case "cmi.core.session_time":
          _this.lastError = "404";
          return "";
        case "cmi.launch_data":
        case "cmi.core.lesson_location":
        case "cmi.core.lesson_status":
        case "cmi.core.score.raw":
        case "cmi.suspend_data":
        case "cmi.core.student_id":
        case "cmi.core.student_name":
        case "cmi.core.credit":
        case "cmi.core.entry":
        case "cmi.core.total_time":
          break;
        default:
          _this.lastError = "401";
          return "";
      }
      console.log(
        `%c GET "${parametro}" return: ${
          _this.scormData[parametro] || ""
        } type: ${typeof _this.scormData[parametro]}`,
        "color:orangered"
      );
      return _this.scormData[parametro] || "";
    }

    async function LMSSetValue(parametro, valor) {
      console.log(
        `%c begin SET "${parametro + ": " + valor}" type: ${typeof valor}`,
        "color:darkviolet"
      );
      switch (parametro) {
        case "cmi.core._children":
          _this.lastError = 402;
          return "false";
        case "cmi.core.student_id":
          _this.lastError = 403;
          return "false";
        case "cmi.core.student_name":
          _this.lastError = 403;
          return "false";
        case "cmi.core.credit":
          _this.lastError = 403;
          return "false";
        case "cmi.core.entry":
          _this.lastError = 403;
          return "false";
        case "cmi.core.score._children":
          _this.lastError = 402;
          return "false";
        case "cmi.core.total_time":
          _this.lastError = 403;
          return "false";
        case "cmi.core.session_time":
          let separado = valor.split(":");
          if (
            separado.length !== 3 ||
            isNaN(separado[0]) ||
            isNaN(separado[1]) ||
            isNaN(separado[2])
          ) {
            _this.lastError = 205;
            return "false";
          }
          break;
        case "cmi.launch_data":
          _this.lastError = 403;
          return "false";
        case "cmi.core.lesson_location":
          if (valor.length > 255) {
            _this.lastError = 405;
            return "false";
          }
          break;
        case "cmi.core.lesson_status":
          /* console.log("cmi.core.lesson_status", valor); */
          if (
            valor !== "passed" &&
            valor !== "completed" &&
            valor !== "failed" &&
            valor !== "incomplete" &&
            valor !== "browsed" &&
            valor !== "not attempted"
          ) {
            _this.lastError = 405;
            return "false";
          } else if (valor === "completed") {
            LMSFinish();
          }
          break;
        case "cmi.core.score.raw":
          if (
            valor !== "" &&
            isNaN(valor) &&
            parseInt(valor) < 0 &&
            parseInt(valor) > 100
          ) {
            _this.lastError = 405;
            return "false";
          }
          break;
        case "cmi.core.exit":
          _this.valorExit = valor;
          if (
            valor !== "time-out" &&
            valor !== "suspend" &&
            valor !== "logout" &&
            valor !== ""
          ) {
            _this.lastError = 405;
            return "false";
          }
          break;
        case "cmi.suspend_data":
          if (valor.length > 4096) {
            _this.lastError = 405;
            return "false";
          } else {
            valor = JSON.stringify(JSON.parse(valor.replace(/'/g, '"')));
          }
          break;
        default:
          _this.lastError = 401;
          return "false";
      }

      _this.scormData[parametro] = valor;

      let result = "";
      let query = `parametro=${parametro}&valor=${valor}&${_this.defaultUrlParams}`;
      await fetch(`${api.scorm_define_valor}?${query}`, {
        method: "get",
        headers: { token: _this.token },
      })
        .then((res) => {
          _this.lastError = 0;
          result = true;
        })
        .catch((err) => {
          _this.lastError = 101;
          result = false;
        });
      console.log(
        `%c end SET "${parametro + ":" + valor}"`,
        "color:darkviolet",
        "return:",
        result ? "true" : "false",
        `(string)`
      );
      return result ? "true" : "false";
    }

    async function LMSFinish() {
      console.log("FINISH debug");
      let result = false;
      let query = `valorExit=${_this.valorExit || "suspend"}&${
        _this.defaultUrlParams
      }`;
      try {
        _this.finalizaCapitulo2();
      } catch (error) {
        console.log("finalizaCapitulo erro2", error);
        throw new Error(error);
      }
      await fetch(`${api.scorm_finalizar}?${query}`, {
        method: "get",
        headers: { token: _this.token },
      })
        .then((res) => {
          _this.lastError = 0;
          result = true;
        })
        .catch((err) => {
          _this.lastError = 101;
          result = false;
        });
      return result ? "true" : "false";
    }

    function LMSCommit(commitInput) {
      /*   console.log("*** LMSCommit: " + commitInput); */
      return "true";
    }

    function LMSGetLastError() {
      //  console.log("*** LMSGetLastError: ", _this.lastError);
      return _this.lastError;
    }

    function LMSGetDiagnostic(errorCode) {
      //console.log("*** LMSGetDiagnostic: " + errorCode);
      return "";
    }

    function LMSGetErrorString(valor) {
      //console.log("*** LMSGetErrorString: " + valor);
      switch (valor) {
        case 0:
          return "No error";
        case 101:
          return "General exception";
        case 201:
          return "Invalid argument error";
        case 202:
          return "Element cannot have children";
        case 203:
          return "Element is not an array and cannot have a count";
        case 301:
          return "Not initialized";
        case 401:
          return "Not implemented error";
        case 402:
          return "Invalid set value, element is a keyword";
        case 403:
          return "Element is read only";
        case 404:
          return "Element is write only";
        case 405:
          return "Incorrect Data Type";
        default:
          return "";
      }
    }
  };
}

export default ScormScript;
