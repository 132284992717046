import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getSurvey = async ({ surveyId }) => {
  const res = await axios.cadernoVirtualBase(
    "https://api.cadernovirtual.com.br/survey-service/research",
    {
      params: {
        id: surveyId,
      },
    }
  );
  return res.object[0];
};

export const useSurvey = ({ config, surveyId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["survey", { surveyId }],
    queryFn: () => getSurvey({ surveyId }),
  });
};
