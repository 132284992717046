import { Box, Button } from "@mui/material";

export default function CarouselScrollButtons({
  currentItem,
  totalItems,
  containerRef,
  deltaScroll,
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        disabled={currentItem === 0}
        onClick={() =>
          containerRef.scroll({
            left: containerRef.scrollLeft - deltaScroll,
          })
        }
      >
        {"< ANTERIOR"}
      </Button>
      <Button
        disabled={currentItem === totalItems - 1}
        onClick={() =>
          containerRef.scroll({
            left: containerRef.scrollLeft + deltaScroll,
          })
        }
      >
        {"PRÓXIMO >"}
      </Button>
    </Box>
  );
}
