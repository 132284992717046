import { InsertDriveFileOutlined, Link } from "@mui/icons-material";
import { Box, SvgIcon } from "@mui/material";

export default function CardImage({
  extension = "URL",
  url = null,
  isImage = false,
  showThumbnail,
}) {
  return (
    <>
      {isImage ? (
        <Box
          component="img"
          sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          alt=""
          src={url}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {showThumbnail ? (
            <iframe style={{ height: "100%" }} src={url}></iframe>
          ) : (
            <SvgIcon
              sx={{
                margin: "10px",
                fontSize: "200px",
                color: "var(--primary_color_main)",
              }}
            >
              {extension === "URL" ? (
                <Link />
              ) : (
                <>
                  <InsertDriveFileOutlined />
                  <rect fill="white" width="24" height="9" x="0" y="10"></rect>
                  <text
                    x="12"
                    y="17"
                    width="22"
                    textAnchor="middle"
                    fontSize="8px"
                    fontWeight="700"
                    color="var(--primary_color_main)"
                  >
                    {extension.toUpperCase()}
                  </text>
                </>
              )}
            </SvgIcon>
          )}
        </Box>
      )}
    </>
  );
}
