import { Box } from "@mui/material";
import EmbeddedInput from "./EmbeddedInput";
import { EmbeddedContent } from "./EmbeddedContent";

export default function EmbeddedComponent({
  isEditing,
  value,
  onChange,
  ...rest
}) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // iframe: { width: "100%", height: "100%" },
      }}
    >
      {isEditing && (
        <EmbeddedInput
          defaultValues={{ url: value }}  
          onSubmit={(url) => {
            onChange(url);
          }}
        />
      )}
      {!!value && <EmbeddedContent value={value} />}
    </Box>
  );
}
