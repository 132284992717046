//App State Managment
import { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { baseUrl, getClient } from "../../../shared/config";

//Utils
// import _cookies from "../../../shared/utils/_cookies";
import _cookiesChunked from "../../../shared/utils/_cookiesChunked";

//Actions
import { listaMenu, listaMenuCanais } from "../../../shared/store/routes";
import {
  setAuth,
  login,
  logout,
  recuperaSenha,
  AUTH_GET_TOKEN,
} from "../../../shared/store/auth";
import { setParams } from "../../../shared/store/app";

//Components
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import MuiPaper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MuiIconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loading from "../../components/Loading";
import { Box, useMediaQuery } from "@mui/material";
import _cookies from "../../../shared/utils/_cookies";
import { listaPesquisas } from "../../../shared/store/survey";
import LoginSurveys from "./LoginSurveys";
import ForgotPassword from "./ForgotPassword";
import { useQuery } from "../../../shared/hooks/useQuery";
import {
  CLIENT_HAS_INTEGRATED_LOGIN,
  CLIENT_INTEGRATED_LOGIN_LINK,
} from "../../../shared/constants/login";
import { listaPesquisasLogin } from "../../../shared/store/survey";
import WhiteLogo from "../../../shared/assets/images/logo_branca.png";
import { useTheme } from "@emotion/react";
import { useProgressiveImage } from "../../../shared/hooks/useProgressiveImage";
const BACKGROUND_IMAGE = `url(${baseUrl}/img/login-bg.jpg)`;
const RESPONSIVE_BACKGROUND_IMAGE = `${baseUrl}/img/login-bg-9x16.jpg`;

if (getClient() == "UNISORRISO" || getClient() == "PROZPERAR") {
  require(`./${getClient().toUpperCase()}Script`);
}

//Styled Components
const LoginBackground = styled("div")(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  width: "100vw",
  position: "absolute",
  zIndex: -1,
  top: 0,
  left: 0,
}));

const LoginFormWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(theme.breakpoints.values.md)]: {
    backgroundColor: "rgba(51,51,51, 0.6)",
    position: "absolute",
    right: "20%",
  },
  display: "flex",
  alignItems: "center",
  maxWidth: "500px",
  margin: "auto",
  minHeight: "calc(100vh - 110px)",
  maxHeight: "100vh",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  overflow: "hidden",
}));

const FormLogo = styled("img")(({ theme }) => ({
  [theme.breakpoints.up(theme.breakpoints.values.sm)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.md)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.lg)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.xl)]: {},

  width: "auto",
  margin: "auto",
  maxHeight: "30vh",
  // width: "auto",
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  margin: "auto",
  padding: theme.spacing(6),
  maxWidth: "475px",
  zIndex: "1",
}));

const PasswordTextFieldWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
}));

const PasswordTextField = styled(TextField)(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const PasswordIconButton = styled(MuiIconButton)(({ theme }) => ({
  position: "absolute",
  alignSelf: "center",
  marginRight: "3px",
}));

const FooterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  justifyContent: "space-between",
  height: "110px",
  width: "100%",
  bottom: 0,
  [theme.breakpoints.down("md")]: {
    backgroundColor: "none",
  },
  [theme.breakpoints.up("md")]: {
    backgroundColor: "rgba(51,51,51, 0.6)",
  },
}));

const Copyright = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.common.white,
  [theme.breakpoints.down("md")]: {
    opacity: "0",
  },
}));

const CadernoFooterLogo = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

function Login({ app, auth, ...props }) {
  const username = useQuery().get("username");
  const password = useQuery().get("password");
  const INITIAL_VALUE = {
    user: username ? atob(username) : "",
    password: password ? atob(password) : "",
    enableCookies: true,
    visible: false,
  };
  const ref = useRef();
  const navigate = useNavigate();
  const redirectTo = useQuery().get("redirectTo");

  const [state, setState] = useState(INITIAL_VALUE);
  const [errorMessage, setErrorMessage] = useState("");

  const [canLogIn, setCanLogIn] = useState(false);
  const theme = useTheme();
  const isDisplaySmall = useMediaQuery(theme.breakpoints.down("md"));
  const loginFormId = "login-form";

  const fail = () => {
    props.logout();
    setState((prev) => ({ ...prev, loading: false, password: "" }));
  };

  const handleSubmit = async (event, overRide = false) => {
    if (!overRide) {
      if (
        !(
          (event.type === "keypress" && event.key === "Enter") ||
          event.type === "click"
        )
      )
        return;
    }
    setErrorMessage("");

    const { login } = props;
    const { user, password } = state;

    if (!user || !password) return false;

    try {
      await login({ user, password });
    } catch (error) {
      setErrorMessage(error?.message || "Erro no login!");
      fail();
    }
  };

  const writeCookies = (auth) => {
    _cookies.setItem("auth", auth);

    _cookies.setItem("host", window.location.host.replace("www.", ""), false);
    _cookies.setItem("title", document.title, false);
  };

  useEffect(async () => {
    const { token } = auth;

    if (auth?.user?.profile && canLogIn) {
      writeCookies(auth);

      if (auth.user.profile.isGestor && !auth.user.profile.isGestorAluno) {
        _cookies.remove("auth");
        _cookies.remove("host");
        _cookies.remove("popupCount");
        _cookies.remove("title");
        _cookiesChunked.remove("params");
        window.location.href = `${window.location.origin}/REDIRECT_GESTOR.ASPX?token=${token}`;
      } else {
        console.log("navigate");
        const res = await props.listaPesquisasLogin(
          auth.user.alunoid,
          auth.token
        );
        const isSomeMandatory = res?.some(
          (survey) => survey.OBRIGATORIA == "S"
        );
        if (!isSomeMandatory && canLogIn) {
          navigate(redirectTo ? `../${redirectTo}` : "/", { replace: true });
        }
      }
    }
  }, [auth?.user?.profile, canLogIn]);
  useEffect(() => {
    if (username && password) {
      handleSubmit(null, true);
    }
  }, [username]);

  useEffect(() => {
    const getParams = async () => {
      const { listaMenu, setParams, listaMenuCanais } = props;
      const { token } = auth;
      await setParams(token, auth?.user.alunoid);
      await listaMenu(token, auth?.user.alunoid);
      await listaMenuCanais(auth?.token, auth?.user.alunoid);
      // setTimeout(() => {
      //   navigate("/trilhas");
      // }, 1000 * 2);
    };
    if (auth?.token) {
      getParams();
    }
  }, [auth?.token]);
  console.log({ RESPONSIVE_BACKGROUND_IMAGE });
  const responsiveBackgroundImage = useProgressiveImage(
    RESPONSIVE_BACKGROUND_IMAGE
  );
  const handleChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;
    setState({
      ...state,
      [name]: name === "enableCookies" ? checked : value,
    });
  };

  const handlePasswordVisibleToggle = (event) => {
    setState((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const handleForgottenPasswordClick = (event) => {
    setOpenPasswordModal(true);
  };

  const additionalFooter = useProgressiveImage(
    `${baseUrl}/img/logo_rodape.png`
  );

  const renderFooter = (
    <FooterWrapper>
      <Copyright>© Caderno Virtual. Direitos reservados.</Copyright>
      <Box sx={{ display: "flex" }}>
        <CadernoFooterLogo
          src={additionalFooter.src}
          sx={{
            maxWidth: "100px",
            display: {
              xs: "none",
              md: additionalFooter.isSuccess ? "block" : "none",
            },
          }}
          alt="Logo"
        />
        <CadernoFooterLogo
          src={`${baseUrl}/img/logo-caderno-virtual-footer.png`}
          sx={{ maxWidth: "100px", display: { xs: "none", md: "block" } }}
          alt="Logo"
        />
      </Box>
    </FooterWrapper>
  );

  const isLoggingIn = auth?.status?.[AUTH_GET_TOKEN] === "loading" ?? false;
  return (
    <LoginBackground
      sx={{
        backgroundImage: {
          xs: responsiveBackgroundImage.isSuccess
            ? `url(${responsiveBackgroundImage.src})`
            : BACKGROUND_IMAGE,
          md: BACKGROUND_IMAGE,
        },
      }}
    >
      <LoginFormWrapper>
        <Paper
          ref={(currRef) => {
            ref.current = currRef;
          }}
          id={loginFormId}
        >
          <FormLogo src={`${baseUrl}/img/logo_login.png`} alt="" />
          <br />
          <br />
          <Typography variant="h5" component="h1" color="text.disabled">
            Login
          </Typography>
          <br />
          <FormGroup>
            <TextField
              name="user"
              label="Usuário"
              type="text"
              value={state.user}
              onChange={handleChange}
              onKeyPress={handleSubmit}
              InputLabelProps={{ shrink: true }}
            />
            <br />
            <InputLabel
              sx={{ textAlign: "right", "&:hover": { cursor: "pointer" } }}
              onClick={handleForgottenPasswordClick}
            >
              Esqueceu sua senha?
            </InputLabel>
            <PasswordTextFieldWrapper>
              <PasswordTextField
                name="password"
                label="Senha"
                type={state.visible ? "text" : "password"}
                value={state.password}
                onChange={handleChange}
                onKeyPress={handleSubmit}
                InputLabelProps={{ shrink: true }}
              />
              <PasswordIconButton onClick={handlePasswordVisibleToggle}>
                {state.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </PasswordIconButton>
            </PasswordTextFieldWrapper>
          </FormGroup>
          {errorMessage && (
            <>
              <br />
              <Typography color="error" textAlign="center">
                {errorMessage}
              </Typography>
            </>
          )}
          <br />
          <Button
            onClick={handleSubmit}
            variant="outlined"
            disabled={isLoggingIn}
            sx={{ marginBottom: "1em" }}
          >
            Entrar
            {isLoggingIn && (
              <Box
                sx={{
                  position: "relative",
                  marginLeft: "1.25rem",
                  marginRight: "0.5rem",
                }}
              >
                <Loading size="1.5rem" />
              </Box>
            )}
          </Button>
          {CLIENT_HAS_INTEGRATED_LOGIN() && (
            <Button
              sx={{ marginBottom: "1em" }}
              onClick={() => {
                window.location.replace(CLIENT_INTEGRATED_LOGIN_LINK());
              }}
            >
              Login integrado
            </Button>
          )}
        </Paper>
      </LoginFormWrapper>
      {renderFooter}

      <LoginSurveys setCanLogIn={setCanLogIn} />
      <ForgotPassword
        open={openPasswordModal}
        onClose={() => {
          setOpenPasswordModal(false);
        }}
      ></ForgotPassword>
    </LoginBackground>
  );
}

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
  surveys: state.survey.surveys,
  requestsStatus: state.survey.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listaMenu,
      listaMenuCanais,
      setAuth,
      login,
      logout,
      recuperaSenha,
      setParams,
      listaPesquisas,
      listaPesquisasLogin,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
