import { Box, CircularProgress, Container, Grid } from "@mui/material";
import EmptyListLabel from "../EmptyListLabel";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function GridList({
  sx = {},
  loading = false,
  empty = false,
  children,
  emptyLabel = "Sem dados",
  TopOfListComponent = (ref) => <div ref={ref}></div>,
  BottomOfListComponent = (ref) => <div ref={ref}></div>,
  onTopEntersView = () => {
    // console.log("top inView");
  },
  onTopLeavesView = () => {
    // console.log("top outOfView");
  },
  onBottomEntersView = () => {
    // console.log("bottom inView");
  },
  onBottomLeavesView = () => {
    // console.log("bottom outOfView");
  },
}) {
  const top = useInView();
  const bottom = useInView();
  useEffect(() => {
    if (top.inView) {
      onTopEntersView();
    } else {
      onTopLeavesView();
    }
  }, [top.inView]);
  useEffect(() => {
    if (bottom.inView) {
      onBottomEntersView();
    } else {
      onBottomLeavesView();
    }
  }, [bottom.inView]);

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (empty) {
    return <EmptyListLabel text={emptyLabel} />;
  }
  return (
    <Box sx={{ minHeight: "100%" }}>
      {TopOfListComponent(top.ref)}
      <Box
        container
        disableGutters
        sx={{
          width: "100%",
          boxSizing: "border-box",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(270px,1fr))",
          gridTemplateRows: "max-content",
          gap: "10px",
          maxWidth: "100%",
          ...sx
        }}
      >
        {children}
      </Box>
      {BottomOfListComponent(bottom.ref)}
    </Box>
  );
}
