import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Input,
  TextField,
  Typography,
} from "@mui/material";

export default function FileInput(props) {
  const {
    title = null,
    onClickUpload = () => {},
    url = "",
    onSetUrl = (newValue) => {},
    uploadLabel = "Fazer Upload",
  } = props;

  const [fileProvider, setFileProvider] = useState(null);

  const hasFileSelected = fileProvider === "file" && !!url;

  return (
    <Box
      sx={{ height: "auto", paddingY: "1rem" }}
      textAlign="center"
      margin="9px"
      borderRadius
    >
      {fileProvider === null ? (
        <>
          {title && (
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: 600 }}>
              {title}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            {!props.noUploadLink && (
              <Button onClick={() => setFileProvider("url")} variant="outlined">
                Digitar Link
              </Button>
            )}
            <Button
              onClick={() => {
                setFileProvider("file");
                onClickUpload();
              }}
              variant="outlined"
            >
              {uploadLabel}
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          {fileProvider === "url" && (
            <FormControl>
              <TextField
                id="file-link"
                type="text"
                size="small"
                placeholder="Link"
                onChange={(event) => onSetUrl(event.target.value)}
              />
            </FormControl>
          )}
          {fileProvider === "file" && (
            <Button onClick={() => onClickUpload()} variant="outlined">
              {hasFileSelected ? "Trocar" : "Fazer Upload"}
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setFileProvider(null);
              onSetUrl("");
            }}
          >
            Cancelar
          </Button>
        </Box>
      )}
    </Box>
  );
}
