import { useState } from "react";
import isMobile from "../../../../../../shared/utils/isMobile";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close, Launch } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export function EmbeddedContent({ value }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  return isMobile() ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "16 / 9",
        width: "100%",
        height: "auto",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px",
      }}
    >
      <>
        <Button
          startIcon={<Launch />}
          variant="contained"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          Abrir Conteúdo em tela cheia
        </Button>
        <Dialog sx={{ backgroundColor: "blue" }} fullScreen open={dialogOpen}>
          <DialogTitle>Conteúdo do capítulo</DialogTitle>
          <IconButton
            onClick={() => {
              setDialogOpen(false);
            }}
            sx={{ position: "absolute", top: 3, right: 3 }}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <iframe
              src={value}
              style={{ aspectRatio: "16 / 9", width: "100%", height: "100%" }}
            />
          </DialogContent>
        </Dialog>
      </>
    </Box>
  ) : (
    <iframe
      src={value}
      style={{ aspectRatio: "16 / 9", width: "100%", height: "auto" }}
    />
  );
}
