import { DeleteOutlined } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

export default function CardActions({ isSelected, onToggle, onRemove }) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "50%",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected}
            onChange={(event) => onToggle(event.target.checked)}
          />
        }
        label="Incluir"
      />
      <DeleteOutlined
        sx={{
          fontSize: "25px",
          cursor: "pointer",
          color: "red",
        }}
        onClick={onRemove}
      />
    </Box>
  );
}
