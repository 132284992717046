import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import Form from "../Form";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Send } from "@mui/icons-material";

export default function TagsInput({ control, name }) {
  const [textValue, setTextValue] = useState("");

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });

  const handleSubmitTag = () => {
    if (
      !!textValue &&
      !fields.some((field) => {
        return field.name == textValue;
      })
    ) {
      append({ name: textValue });
    }
    setTextValue("");
  };
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmitTag();
      // setTextValue();
    }
  };
  const handleDeleteTag = (index) => (event) => {
    remove(index);
  };

  return (
    <>
      <Box>
        {fields.map((tag, index) => {
          return (
            <Chip
              sx={{ marginRight: 1, marginTop: 1 }}
              key={`tag ${index}`}
              label={tag.name}
              onDelete={handleDeleteTag(index)}
              color="primary"
            />
          );
        })}
      </Box>
      <FormControl>
        <InputLabel htmlFor="tags-text">Tags</InputLabel>
        <Input
          label="tags"
          id="tags-text"
          // {...register("tagsField")}
          value={textValue}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          endAdornment={
            <InputAdornment>
              <IconButton onClick={handleSubmitTag}>
                <Send />
              </IconButton>
            </InputAdornment>
          }
          variant="standard"
        />
      </FormControl>
    </>
  );
}
