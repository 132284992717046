import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const deleteAttachment = async (resourceId) => {
  const res = await axios.middleware(`/Backend.asmx/BK_Excluir_Recurso`, {
    params: { recursoid: resourceId },
  });

  return res;
};

export const useDeleteAttachment = ({ config, videoId } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: deleteAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userAttachments"] });
    },
  });
};
