import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { CARD_ASPECT_RATIO, RELATIVE_CARD_HEIGHT_STRING } from "./constants";

export default function Card({ sx, onClick, isSelected = false, children }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: RELATIVE_CARD_HEIGHT_STRING,
        aspectRatio: `${CARD_ASPECT_RATIO}`,
        scrollSnapAlign: "center",
      }}
    >
      <Box
        sx={{
          margin: "1%",
          height: "98%",
          width: "98%",
          backgroundColor: "white",
          color: "black",
          textAlign: "center",
          transitionProperty: "all",
          transitionDuration: "300ms",
          border: "medium solid",
          borderColor: theme.palette.primary.main,
          borderRadius: "10px",
          position: "relative",
          overflow: "hidden",
          ...sx,
        }}
        onClick={onClick}
      >
        {isSelected && (
          <CheckCircleOutlineOutlined
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: "36px",
              padding: "2px",
              borderBottomRightRadius: "15px",
              color: "white",
              backgroundColor: theme.palette.success.main,
            }}
          />
        )}
        {children}
      </Box>
    </Box>
  );
}
