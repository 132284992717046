import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { createNotification } from "../../../shared/store/notifications";
import { nanoid } from "nanoid";
import { getClient } from "../../../shared/config";
import _cookies from "../../../shared/utils/_cookies";

const processUpload = async ({ identifier, fileName, title, albumId }) => {
  return axios.cadernoVirtualBase.get("/server/api/vimeo/process-upload", {
    supressNotification: true,
    params: {
      identifier,
      fileName,
      vimeoName: title,
      userType: "g",
      userId:
        _cookies.getItem("auth").user?.profile?.isProfessor?.ID ||
        encodeURIComponent(_cookies.getItem("auth").user.nome),
      userAlbumId: albumId,
      origin: getClient(),
    },
  });
};

const uploadVimeoVideo = async ({ file, title, albumId, onProgress }) => {
  console.log({ file });
  const id = `${nanoid()}${file.name.replace(/[^a-z0-9]/gi, "")}`;
  const chunkSize = 5 * 1024 * 1024;
  const totalChunks = Math.ceil(file.size / chunkSize);
  const progressPerChunk = 100 / totalChunks;
  let currentChunk = 0;
  let chunkBeginsAt = 0;
  let chunkEndsAt = Math.min(file.size, chunkSize);

  const fileBlob = new Blob([file], { type: file?.type });

  const uploadNextChunk = async () => {
    if (chunkBeginsAt < file.size) {
      const chunk = fileBlob.slice(chunkBeginsAt, chunkEndsAt);
      const fileOfChunk = new File([chunk], file.name);

      const formData = new FormData();
      formData.append("resumableChunkNumber", currentChunk + 1);
      formData.append("resumableChunkSize", chunkSize);
      formData.append("resumableCurrentChunkSize", chunkEndsAt - chunkBeginsAt);
      formData.append("resumableTotalSize", file.size);
      formData.append("resumableType", file.type);
      formData.append("resumableIdentifier", id);
      formData.append("resumableFilename", file.name);
      formData.append("resumableTotalChunks", totalChunks);

      formData.append("file", fileOfChunk, file.name);

      return axios.cadernoVirtualBase
        .post("/server/api/vimeo/upload", formData, {
          supressNotification: true,
          params: {
            resumableChunkNumber: currentChunk + 1,
            resumableChunkSize: chunkSize,
            resumableTotalSize: file.size,
            resumableType: file.type,
            resumableIdentifier: id,
            resumableFilename: file.name,
            resumableTotalChunks: totalChunks,
            resumableCurrentChunkSize: chunkEndsAt - chunkBeginsAt,
          },
        })
        .then((data) => {
          currentChunk++;
          chunkBeginsAt = chunkEndsAt;
          chunkEndsAt = Math.min(file.size, chunkEndsAt + chunkSize);
          onProgress(progressPerChunk * currentChunk);
          console.log("depois do onprogress");
          return uploadNextChunk();
        })
        .catch((err) => {
          console.log("Erro ao subir chunk", err);
          throw new Error(err);
        });
    } else {
    }
  };
  await uploadNextChunk();
  const res = await processUpload({
    identifier: id,
    fileName: file.name,
    title,
    albumId,
  });
  return res;
};

export const useUploadVimeoVideo = ({ ...config } = {}) => {
  // const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    ...config,
    mutationFn: uploadVimeoVideo,
    onSuccess: () => {
      createNotification({
        type: "success",
        message: "Arquivo enviado com sucesso",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["vimeoVideos"] });
    },
  });
  return { ...mutation, progress: 0 };
};
