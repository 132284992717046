import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { logoUrl, imagesUrl, logoAdicionalUrl } from "../../shared/config";

import { logout } from "../../shared/store/auth";
import Chat from "../../shared/lib/rocketChat/Chat";
import { styled } from "@mui/material/styles";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import MuiChip from "@mui/material/Chip";

import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import MailIcon from "@mui/icons-material/MailOutline";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "../components/DropdownMenu";
import SearchBar from "../components/SearchBar";
import MoodFeedback from "../../features/misc/components/MoodFeedback";
import {
  Book,
  Favorite,
  LocalPolice,
  ManageAccounts,
  MenuBook,
  School,
  Shield,
  Star,
} from "@mui/icons-material";
import _cookies from "../../shared/utils/_cookies";
import TogglePushNotifications from "../../features/misc/components/TogglePushNotifications";

const Logo = styled("img")(({ theme }) => ({
  objectFit: "contain",
  marginRight: 0,
  width: 120,
  height: 40,
}));

function AppBar({ auth, mail, params, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const navigate = useNavigate();
  const userName = auth?.user?.nome?.toUpperCase() || "User"?.toUpperCase();
  const userImage = `${imagesUrl}/${auth?.user?.foto}`;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleGestor = () => {
    setAnchorEl(null);
    window.open(
      `${window.location.origin}/REDIRECT_GESTOR.ASPX?token=${auth?.token}`,
      "_blank"
    );
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileButtonClick = (event) => {
    handleMenuClose(event);
    navigate("/profile");
  };

  const handleMailClick = (event) => {
    handleMenuClose(event);
    navigate("/mail");
  };

  const { logout } = props;
  const handleLogout = async (event) => {
    handleMenuClose(event);
    await logout(auth?.user?.alunoid, auth?.token);
    window.location.reload();
  };

  const theme = useTheme();
  const breakString = theme.breakpoints.up("md");
  const isDisplayMedium = useMediaQuery(breakString);

  const renderLogo = (
    <Logo
      sx={{
        marginLeft: { xs: "0px", sm: "0px" },
        display: { xs: "none", sm: "flex" },
        "&:hover": { cursor: "pointer" },
      }}
      src={params?.LOGOMARCA ? `${logoAdicionalUrl}${params.LOGOMARCA}` : null}
      alt="Logo da plataforma"
      onClick={() => {
        navigate("/");
      }}
    />
  );

  const renderLogoCompany = (
    <Logo
      sx={{
        marginLeft: { xs: "0px", sm: "12px" },
        display: { xs: "none", sm: "flex" },
        "&:hover": { cursor: "pointer" },
      }}
      src={
        params?.logomarca_adicional
          ? `${logoAdicionalUrl}${params.logomarca_adicional}`
          : null
      }
      onClick={() => {
        navigate("/");
      }}
    />
  );

  //Main Menu
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);
  const renderMenu = (
    <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
      <MenuItem onClick={handleProfileButtonClick}>Perfil</MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/");
        }}
      >
        Dashboard
      </MenuItem>
      {auth?.user?.profile?.isGestorAluno && (
        <MenuItem onClick={handleGestor}>Módulo Gestão</MenuItem>
      )}

      <MenuItem onClick={handleLogout}>Sair</MenuItem>
    </Menu>
  );

  //Mobile Menu

  const userChipId = "user-chip";
  const renderUserChip = (
    <Button
      sx={{
        padding: "0px !important",
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={handleProfileMenuOpen}
      id={userChipId}
      aria-label="account of current user"
      aria-controls={menuId}
      aria-haspopup="true"
    >
      <Avatar
        sx={{ height: "36px", width: "36px" }}
        src={userImage}
        alt={userName}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }}
      >
        {!!_cookies.getItem("auth")?.user?.profile?.isGestor?.ID && (
          <Box
            sx={{
              color: "black",
              backgroundColor: "white",
              height: "20px",
              width: "20px",
              borderRadius: "9999px 0 0 9999px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <School size="small" sx={{ fontSize: "15px" }} />
          </Box>
        )}
        {!!_cookies.getItem("auth")?.user?.profile?.isProfessor?.ID && (
          <Box
            sx={{
              color: "black",
              backgroundColor: "white",
              height: "20px",
              width: "20px",
              borderRadius: "9999px 0 0 9999px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ManageAccounts size="small" sx={{ fontSize: "15px" }} />
          </Box>
        )}
      </Box>
    </Button>
  );

  return (
    <MuiAppBar
      elevation={0}
      // disableGutters
      sx={{ backgroundColor: "menu.backgroundheader", padding: "0px" }}
      position="static"
    >
      <Container
        disableGutters
        maxWidth="100%"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "60px",
            padding: "0 24px",
            alignItems: "center",
          }}
        >
          {renderLogo}
          {params?.logomarca_adicional ? renderLogoCompany : null}
        </Box>
        <Toolbar
          disableGutters
          sx={{
            paddingLeft: 2,
            display: "flex",
            justifyContent: "space-between",
            height: "60px",
          }}
        >
          <Box sx={{ flexGrow: "2" }}>
            <SearchBar sx={{ width: "100%" }} />
          </Box>
          <Box
            sx={{
              paddingLeft: 1,
              display: "flex",
              gap: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Badge badgeContent={mail.unread} color="error">
              <IconButton
                sx={{ backgroundColor: "white", height: "36px", width: "36px" }}
                size={isDisplayMedium ? "large" : "small"}
                aria-label="show 13 new notifications"
                onClick={handleMailClick}
              >
                <MailIcon />
              </IconButton>
            </Badge>
            <TogglePushNotifications />
            <MoodFeedback />
            {renderUserChip}
          </Box>
        </Toolbar>
        {renderMenu}
      </Container>
    </MuiAppBar>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    params: state.app.params,
    mail: state.mail,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
