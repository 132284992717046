import React from "react";
import Discursive from "./Discursive";
import { Alternatives, DropdownList, Quality } from "./MultipleChoice";
import TrueOrFalse from "./TrueOrFalse";
import { FiveStars, TenStars } from "./Stars";
import QuestionList from "./QuestionList";
import { AutocompleteList } from "./AutocompleteList";

const questionCategories = [
  {
    category: "multipleChoice",
    label: "Múltipla escolha",
    usableInEvaluation: true,
    usableInSurvey: true,
    default: {
      type: "evaluation",
      template: "trueOrFalse",
      label: "Verdadeiro ou Falso",
      listOptions: [
        { optionDescription: "Verdadeiro" },
        { optionDescription: "Falso" },
      ],
    },
    types: [
      {
        type: "evaluation",
        label: "Avaliativa",
        defaultTemplate: "trueOrFalse",
        templates: [
          {
            template: "trueOrFalse",
            label: "Verdadeiro ou Falso",
            component: TrueOrFalse,
            listOptions: [
              { optionDescription: "Verdadeiro" },
              { optionDescription: "Falso" },
            ],
            usableInEvaluation: true,
            usableInSurvey: true,
          },
          {
            template: "yesOrNo",
            label: "Sim ou não",
            component: Alternatives,
            listOptions: [
              { optionDescription: "Sim" },
              { optionDescription: "Não" },
            ],
            usableInEvaluation: true,
            usableInSurvey: true,
          },
          {
            template: "alternatives",
            label: "Múltipla escolha",
            component: Alternatives,
            listOptions: [
              { optionDescription: "Opção 1" },
              { optionDescription: "Opção 2" },
              { optionDescription: "Opção 3" },
            ],
            usableInEvaluation: true,
            usableInSurvey: true,
          },
        ],
      },
      {
        type: "quantity",
        label: "Quantitativa",
        defaultTemplate: "oneToFiveStars",
        usableInEvaluation: false,
        usableInSurvey: true,
        templates: [
          {
            template: "oneToFiveStars",
            label: "Uma a cinco estrelas",
            listOptions: [{ optionDescription: "0-5" }],
            component: FiveStars,
            usableInEvaluation: false,
            usableInSurvey: true,
          },
          {
            template: "oneToTenStars",
            label: "Uma a dez estrelas",
            listOptions: [{ optionDescription: "0-10" }],
            component: TenStars,
            usableInEvaluation: false,
            usableInSurvey: true,
          },
          {
            template: "satisfactoryOrUnsatisfactory",
            label: "Satisfatório ou insatisfatório",
            listOptions: [
              { optionDescription: "Satisfatório" },
              { optionDescription: "Insatisfatório" },
            ],
            component: Quality,
            usableInEvaluation: false,
            usableInSurvey: true,
          },
          {
            template: "terribleBadRegularGood",
            label: "Péssimo, ruim, regular ou bom",
            listOptions: [
              { optionDescription: "Péssimo" },
              { optionDescription: "Ruim" },
              { optionDescription: "Regular" },
              { optionDescription: "Bom" },
              { optionDescription: "Ótimo" },
            ],
            component: Quality,
            usableInEvaluation: false,
            usableInSurvey: true,
          },
          {
            template: "highMediumLow",
            label: "Alto, médio ou baixo",
            listOptions: [
              { optionDescription: "Alto" },
              { optionDescription: "Médio" },
              { optionDescription: "Baixo" },
            ],
            component: Quality,
            usableInEvaluation: false,
            usableInSurvey: true,
          },
        ],
      },
    ],
  },
  {
    category: "discursive",
    label: "Discursiva",
    component: Discursive,
    usableInEvaluation: false,
    usableInSurvey: true,
  },
  {
    category: "autoCompleteList",
    label: "Lista com preenchimento automático",
    component: AutocompleteList,
    usableInEvaluation: true,
    usableInSurvey: true,
    listOptions: [{ optionDescription: "Opção 1,Opção 2,Opção 3,Opção 4" }],
    types: [
      {
        type: "autoCompleteList",
        label: "Lista com preenchimento automático",
        defaultTemplate: "autoCompleteList",
        templates: [
          {
            template: "autoCompleteList",
            label: "Lista com preenchimento automático",
            component: TrueOrFalse,
            listOptions: [{ optionDescription: "Opcão 1,Opcão 2,Opcão 3" }],
            usableInEvaluation: false,
            usableInSurvey: true,
          },
        ],
      },
    ],
  },
];

export const questionTypes = questionCategories.flatMap((categoryItem) => {
  if (categoryItem?.types) {
    return categoryItem.types.flatMap((typeItem) =>
      typeItem.templates.map((templateItem) => ({
        label: templateItem.label,
        category: categoryItem.category,
        type: typeItem.type,
        template: templateItem.template,
        component: templateItem.component,
        listOptions: templateItem.listOptions,
        usableInEvaluation: templateItem.usableInEvaluation,
        usableInSurvey: templateItem.usableInSurvey,
      }))
    );
  } else {
    return categoryItem;
  }
});

export const newQuestionOptions = [
  { ...questionCategories[0], optionLabel: "Nova questão" },
  {
    category: "questions",
    optionLabel: "Questões já cadastradas",
    component: QuestionList,
  },
];

export const QuestionComponents = ({
  typeIndex = null,
  exhibition = true,
  category = "multipleChoice",
  type = "evaluation",
  template = "trueOrFalse",
  ...props
}) => {
  const categoryObject = questionCategories.find(
    (item) => item.category === category
  );
  const typeObject = categoryObject?.types
    ? categoryObject.types.find((item) => item.type === type)
    : {};

  const templateObject = typeObject?.templates
    ? typeObject.templates.find((item) => item.template === template)
    : {};

  var component = categoryObject?.component ?? templateObject?.component;

  if (exhibition) {
    const { listOptions } = props;
    // switch(typeObject.type){
    //   case :
    // }
    if (category === "multipleChoice") {
      if (listOptions?.filter((opt) => !opt.remove).length === 1) {
        switch (listOptions[0].optionDescription) {
          case "0-5":
            component = FiveStars;
            break;
          case "0-10":
          default:
            component = TenStars;
            break;
        }
      } else if (type === "quantity") {
        component = Quality;
      } else if (type === "dropdownList") {
        component = DropdownList;
        // props.listOptions = [];
      } else {
        // if (listOptions?.length === 2) {
        //   component = TrueOrFalse;
        // } else {
        //   component = Alternatives;
        // }
        component = Alternatives;
        props.exhibition = exhibition;
      }
    }

    if (category === "discursive") {
      component = Discursive;
    }

    if (category === "questions") {
      component = QuestionList;
    }
  }

  if (!component && typeIndex !== null && typeIndex !== -1) {
    component = questionTypes[typeIndex].component;
  }

  if (!component) component = TrueOrFalse;

  return React.createElement(component, props);
};

export default questionCategories;
