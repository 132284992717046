import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";

const EmptyListLabel = ({ text = "" }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          color: theme.palette.grey[400],
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default EmptyListLabel;
