import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getScormInfo = async ({ chapterId }) => {
  const res = await axios.cadernoVirtualBase("/courses-service/scorm", {
    params: { chapterId },
  });

  return res.object;
};

export const useScormInfo = ({ config, chapterId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["scormInfo", { chapterId }],
    queryFn: () => getScormInfo({ chapterId }),
  });
};
    