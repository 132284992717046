import {
  Box,
  Container,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ScormContent from "../pages/Course/pages/ChapterPage/ui/partials/ScormContent";
import useModuleList from "../pages/Course/common/hooks/useModuleList";
import useModuleListAPI from "../pages/Course/common/hooks/useModuleListAPI";
import AbsoluteContentCard from "../pages/Course/ui/partials/AbsoluteContentCard";
import { useEffect, useState } from "react";
import EvaluationContent from "../pages/Course/pages/ChapterPage/ui/partials/EvaluationContent";
import Loading from "./Loading";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { listViewed } from "../pages/Course/common/redux/CourseActionCreators";
import { useOrder } from "../../shared/utils/useOrder";
import { useLocation, useParams } from "react-router-dom";
import { acessarCapitulo } from "../pages/ChapterPage/common/redux/chapterReducer";
import LinkChapter from "../../features/chapters/components/LinkChapter";
function RenderContents({
  currentChapter,
  course = null,
  auth,
  listViewed,
  ...props
}) {
  const location = useLocation();

  const isCourse = location?.pathname?.includes("/curso");

  const [list, { insertList }] = useModuleList();
  const [fetchList] = useModuleListAPI(currentChapter);

  const [loadingList, setLoadingList] = useState([]);
  const [hasContents, setHasContents] = useState(true);
  const { disciplinaid } = useParams();
  const { acessarCapitulo } = props;

  useEffect(() => {
    setLoadingList(Array(list.length).fill(true));
  }, [list]);

  useEffect(() => {
    const mountPage = async () => {
      setLoadingList([]);
      setHasContents(true);

      const currListProm = fetchList();
      const currList = await currListProm;
      const sanitizedList = currList.map((content) => ({
        id: content.chapterContentId,
        type: content.contentType,
      }));

      insertList(sanitizedList);

      if (sanitizedList?.length > 0) {
        setHasContents(true);
      } else {
        setHasContents(false);
      }
    };
    mountPage();
  }, [currentChapter]);

  const chapter = course
    ? course.chapters.find(
        (chapter) => chapter.capituloid === Number(currentChapter)
      )
    : null;

  useEffect(() => {
    if (!!currentChapter && !!course?.enrollment?.MATRICULAID && !!auth.token) {
      listViewed(currentChapter, course?.enrollment.MATRICULAID, auth.token);
      acessarCapitulo(auth?.token, auth.user?.alunoid, {
        capituloid: currentChapter,
        matriculaid: course?.enrollment?.MATRICULAID,
        disciplinaid,
      });
    }
  }, [currentChapter, course?.enrollment?.MATRICULAID, auth?.token]);

  const isScorm = chapter && chapter?.tipo === "SCORM";

  const loading =
    !isScorm &&
    hasContents &&
    (loadingList.length === 0 ||
      loadingList.findIndex((value) => value === true) !== -1);

  const isEvaluation =
    (chapter && chapter?.tipo === "AVALIACAO") || chapter?.tipo === "ENQUETE";

  const theme = useTheme();
  const renderScorm = () => (
    <ScormContent
      chapter={chapter}
      capituloid={chapter.capituloid}
      matriculaid={course?.enrollment?.MATRICULAID}
    />
  );

  const renderEvaluation = () => (
    <Paper
      sx={{
        marginX: "24px",
        marginY: "12px",
        padding: "12px",
        overflow: "visible",
        [theme.breakpoints.down("sm")]: {
          marginX: "0.5rem",
          padding: "0.25rem",
        },
      }}
    >
      <EvaluationContent
        isEnquete={chapter?.tipo === "ENQUETE"}
        evaluationId={chapter.avaliacaoid || 35}
      />
    </Paper>
  );

  const breakString = theme.breakpoints.up("md");
  const isDisplayLarge = useMediaQuery(breakString);

  const { addItem, getItemPosition } = useOrder();

  const renderCards = () => (
    <Paper
      sx={{
        padding: "0.25rem",
        overflow: "visible",
        borderRadius: { xs: "0", sm: "0.25rem" },
        margin: { xs: "0", sm: "0.5rem" },
        marginX: "auto",
        paddingBottom: { xs: "52px", md: "0.25rem" },

        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
        // [theme.breakpoints.up("md")]: {
        //   maxWidth: "calc(100vw - 400px)",
        // },
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isDisplayLarge ? "repeat(2, 1fr)" : "1fr",
          gap: "10px",
        }}
      >
        {loading && <Loading />}
        {list.map((module, index, array) => (
          <AbsoluteContentCard
            key={`content-${module.id}`}
            id={module.id}
            type={module.type}
            onLoad={() =>
              setLoadingList((prev) => {
                prev[index] = false;
                return [...prev];
              })
            }
            addItem={addItem}
            getItemPosition={getItemPosition}
            chapterId={currentChapter}
            enrollmentId={course?.enrollment?.MATRICULAID}
          />
        ))}
      </Box>
    </Paper>
  );
  const render = (chapterType) => {
    switch (chapterType) {
      case "CONTEUDO":
        return renderCards();
      case "SCORM":
        return renderScorm();
      case "ENQUETE":
        return renderEvaluation();
      case "AVALIACAO":
        return renderEvaluation();
      case "LINK":
        return (
          <LinkChapter
            url={chapter?.link_video_web}
            enrollmentId={course?.enrollment?.MATRICULAID}
            chapterId={currentChapter}
            courseId={disciplinaid}
          />
        );
      default:
        return renderCards();
    }
  };
  return (
    <Container
      id="render_contents"
      sx={{
        // height: "max-content",
        // overflow: "scroll",
        height: "100%",
        maxWidth: "100%",
        margin: 0,
        "@media screen and (min-width: 1200px)": {
          maxWidth: "unset",
        },
        "& > *": {
          "@media screen and (min-width: 800px)": {
            maxWidth: "calc(100vw - 248px)", // 120 + 2*24 + 100
          },
        },
      }}
      disableGutters
    >
      {render(chapter?.tipo)}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listViewed, acessarCapitulo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RenderContents);
