import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import { CloseCircleOutline } from "mdi-material-ui";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addAttachment, types } from "../../../shared/store/attachment";
import Loading from "../../components/Loading";
import TagsInput from "./TagsInput";

function AttachmentUploader({
  open,
  onClose,
  onFinishUpload = () => {},
  auth,
  addAttachment,
  status,
  isProfessor,
  state,
  ...props
}) {
  const inputFileRef = useRef();
  const [attachmentName, setAttachmentName] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [tags, setTags] = useState(Array(0));

  const addStatus = status?.[types.ADD];

  const isAdding = addStatus === "loading";

  const hasAdded = addStatus === "success";

  const handleSend = () => {
    addAttachment(
      auth.user.username,
      attachmentName,
      tags,
      attachment,
      auth.token,
      isProfessor ? isProfessor.ID : "0"
    ).then((res) => {
      onFinishUpload(res);
    });
  };

  useEffect(() => {
    if (hasAdded) {
      onClose();
    }
  }, [hasAdded]);

  useEffect(() => {
    setAttachmentName("");
    setAttachment(null);
    setTags(Array(0));
  }, [open]);

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            margin: "0",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "1rem",
            lineHeight: "1.6",
            padding: "16px 24px",
          }}
        >
          Upload de Anexos
        </Typography>
        <IconButton onClick={onClose}>
          <CloseCircleOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "350px" }}>
        <InputLabel htmlFor="attachment-title">Título</InputLabel>
        <Input
          id="attachment-title"
          name="tituloAnexo"
          value={attachmentName}
          style={{ width: "100%" }}
          onChange={(event) => setAttachmentName(event.target.value)}
        />
        <InputLabel htmlFor="attachment-tags" style={{ marginTop: 30 }}>
          Tags
        </InputLabel>
        <TagsInput tags={tags} setTags={setTags} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1.5rem",
          }}
        >
          <input
            ref={inputFileRef}
            type="file"
            hidden
            onChange={(event) => {
              const file = event.target?.files[0] ?? null;
              if (file) {
                setAttachment(file);
                setAttachmentName(file.name);
              }
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={() => inputFileRef.current.click()}
              color={"primary"}
              variant={"contained"}
              sx={{ width: "fit-content", marginX: "auto" }}
            >
              SELECIONAR ARQUIVO
            </Button>
            {attachment && (
              <Typography sx={{ fontSize: "0.75rem" }}>
                Arquivo selecionado: <b>{attachment.name}</b>
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {attachment && (
          <Button
            disabled={
              isAdding ||
              attachment.size > 20000000 ||
              attachment.name.length > 25
            }
            onClick={() => {
              handleSend();
            }}
            color={"primary"}
            variant={"contained"}
          >
            {attachment.size > 35000000
              ? "ARQUIVO MUITO GRANDE (máximo 35 MB)"
              : attachment.name.length > 25
              ? "NOME DO ARQUIVO MUITO GRANDE"
              : "ENVIAR E FECHAR"}
          </Button>
        )}
        {isAdding && <Loading />}
        <Button onClick={onClose} color="primary">
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  status: state.attachment.status,
  isProfessor: state.auth.user.profile.isProfessor,
  state: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addAttachment }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentUploader);
