export default function verifyObjKeysMissing(object, keyList) {
  if (typeof object !== "object") return [0, "Module must be an object"];
  const cleanedkeyList =
    typeof keyList === "string" ? keyList.split(" ") : keyList;

  for (let key of cleanedkeyList) {
    if (!!/\?/g.test(key)) continue;
    if (!object.hasOwnProperty(key)) {
      return [0, "Module property " + key + " missing"];
    }
  }
  return [1];
}
