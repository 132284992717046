import {
  IconButton,
  LinearProgress,
  Typography,
  TextField,
  ListItem,
  List,
  ListItemText,
  styled,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const VideoThumbnail = styled("video")(({ theme }) => ({
  aspectRatio: "16 / 9",
  height: "120px",
  width: "auto",
  marginLeft: "0px",
}));

export default function VideosList({
  fileInputRef = null,
  files,
  onDelete = () => {},
  register,
  name,
  progress,
  multiple = false,
}) {
  const handleDelete = (index) => (event) => {
    onDelete(index);
  };
  return (
    <List sx={{ width: "100%", height: "100%" }} disablePadding>
      {files?.map((file, index) => {
        const videoURL = URL.createObjectURL(file);
        const img = new Image();

        img.onload = function () {
          URL.revokeObjectURL(this.src); // clean-up memory
          document.body.appendChild(this); // add image to DOM
        };

        img.src = videoURL;
        const isInProgress = !!progress[index];
        return (
          <ListItem
            sx={{ height: "120px", marginTop: 1 }}
            secondaryAction={
              <IconButton
                disabled={isInProgress}
                sx={{ position: "absolute", top: 3, right: 3 }}
                onClick={handleDelete(index)}
              >
                <Delete />
              </IconButton>
            }
          >
            <VideoThumbnail component="img" src={img.src} />
            <ListItemText
              sx={{ marginLeft: 2 }}
              primary={
                <TextField
                  label="Título"
                  variant="filled"
                  {...register(`${name}.${index}.title`)}
                  disabled={isInProgress}
                />
              }
              secondary={
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Tamanho: {(file.size / (1024 * 1024)).toFixed(2)} MB
                </Typography>
              }
            >
              {isInProgress && (
                <LinearProgress variant="determinate" value={progress[index]} />
              )}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
