import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";
import { createNotification } from "../../../shared/store/notifications";
import { nanoid } from "nanoid";

export const uploadAttachment = async ({ name, tags, file }) => {
  const payload = {
    chapterId: 0,
    name,
    extension: file.name.split(".").slice(-1).pop(),
    teacherId: _cookies.getItem("auth").user?.profile?.isProfessor?.ID,
    tags: tags.join(";"),
  };
  const fileBlob = new Blob([file], { type: file?.type });
  const dataBlob = new Blob([`${JSON.stringify(payload)}`], {
    type: "application/json",
  });
  const body = new FormData();
  const rgx = new RegExp("[^a-zA-Z0-9 -]");
  const id = nanoid().replace(rgx, "");
  body.append("resource", dataBlob);
  body.append(
    "file",
    fileBlob,
    "RECURSO" + id + "." + file.name.split(".").slice(-1).pop()
  );
  return axios.cadernoVirtualBase.post("/courses-service/resource", body);
};

export const useUploadAttachment = ({ config } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: uploadAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userAttachments"] });
      createNotification({
        type: "success",
        message: "Arquivo adicionado com sucesso",
      });
    },
  });
};
