import { useState } from "react";

export function useOrder() {
  const [items, setItems] = useState([]);

  const addItem = ({ row, column, id }) => {
    const newItens = [...items, { row, column, id }];

    newItens.sort((a, b) => {
      const { row: aRow, column: aColumn } = a;
      const { row: bRow, column: bColumn } = b;

      const aPosition = aRow * 2 + aColumn;
      const bPosition = bRow * 2 + bColumn;

      return aPosition - bPosition;
    });

    setItems([...newItens]);
  };

  const getItemPosition = (id) =>
    items.findIndex((item) => item?.id === id) || 0;

  return { addItem, getItemPosition };
}
