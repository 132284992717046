import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";
import { useTheme } from "@mui/material";

const CustomContainer = styled(MuiContainer)(({ theme }) => ({
  flexGrow: "1",
  backgroundColor: theme.palette.grey["200"],
  height: "calc(100dvh - 64px)",
  overflowY: "auto",
  width: "unset",
}));

const PageContent = ({ sx, children }) => {
  const theme = useTheme();
  const largeDisplay = theme.breakpoints.up("md");
  return (
    <CustomContainer
      disableGutters
      maxWidth="unset"
      sx={{
        [largeDisplay]: {
          paddingX: { xs: "0", md: "unset" },
          paddingTop: "13px",
          ...sx,
        },
        padding: "0px",
      }}
    >
      {children}
    </CustomContainer>
  );
};

export default PageContent;
