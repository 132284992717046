import { useState } from "react";

function useLoading() {
  const [loading, setLoading] = useState(true);

  const finishedLoading = () => {
    setLoading(false);
  };

  const renderLoadingComponent = (<div></div>);

  return [loading, finishedLoading.bind(this), renderLoadingComponent];
}

export default useLoading;
