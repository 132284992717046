import clsx from "clsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import ScormScript from "./ScormScript";
import { scormUrl } from "../../../../shared/config";
import useLoading from "../../../../shared/hooks/useLoading";

import Loading from "../../../components/Loading";
import { useScormInfo } from "../../../../features/chapters/api/getSCORMInfo";
import { Backdrop } from "@mui/material";

const styles = (theme) => ({
  embedWrap: {
    position: "relative",
    width: "100%",
    height: "64vh",
    backgroundColor: "#000",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 0,
      paddingBottom: "56.25%",
    },
    "& div": {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      "& iframe": { width: "100% !important", height: "100% !important" },
    },
  },
  embed: {
    zIndex: 4,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    height: "100% !important",
  },
  hidden: { display: "none" },
});

function Scorm({ auth, capituloid, matriculaid, hidden, ...props }) {
  const { chapter, classes } = props;

  const [loading, finishLoading] = useLoading();
  const params = useParams();
  const [src, setSrc] = useState(`${scormUrl}/SCORM${chapter.scorm_pacoteid}`);
  const { isLoading } = useScormInfo({
    chapterId: capituloid,
    config: {
      onSuccess: (data) => {
        setSrc(data.scormContent);
      },
    },
  });
  useEffect(() => {
    const { disciplinaid } = params;
    const handleMount = async () => {
      const Script = new ScormScript(
        auth.user.alunoid,
        capituloid,
        disciplinaid,
        matriculaid,
        auth.token
      );
      await Script.loadData();
      await Script.loadScormApi();
      finishLoading();
    };
    handleMount();
  }, []);

  if (loading || isLoading)
    return (
      <Backdrop sx={{ pointerEvents: "none" }}>
        <Loading />
      </Backdrop>
    );

  return (
    <iframe
      id="scormIframe"
      className={clsx(classes.embed, hidden && "!hidden")}
      style={{ backgroundColor: "grey" }}
      src={src}
      frameBorder="0"
    />
  );
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Scorm)
);
