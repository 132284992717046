import axios from "axios";
import { courseApi } from "../../../../../shared/api/courseApi";

import TextComponent from "../../ui/components/TextComponent";
import DocComponent from "../../ui/components/DocComponent";
import ImageComponent from "../../ui/components/ImageComponent";
import VideoComponent from "../../ui/components/VideoComponent";
import verifyObjKeysMissing from "../../../../../shared/utils/verifyObjKeysMissing";
import AttachmentComponent from "../../ui/components/AttachmentComponent";
import { getClient } from "../../../../../shared/config/urlConfig";
import EmbeddedComponent from "../../ui/components/EmbeddedComponent";

const domain = getClient();

function useModule(moduleIdType) {
  const argList = "id type";
  const initialVerification = verifyObjKeysMissing(moduleIdType, argList);

  if (!initialVerification[0]) throw new Error(initialVerification[1]);
  const { id, type } = moduleIdType;

  const Component = getComponentContent(type);
  return [Component, { fetchModule: fetchModule, saveModule: saveModule }];

  function getComponentContent(type) {
    const VIDEO = "VIDEO";
    const TEXTO = "TEXTO";
    const DOC = "DOCUMENTO";
    const IMAGE = "IMAGEM";
    const ANEXO = "ANEXO";
    const EMBEDDED = "EMBED";

    switch (type) {
      case VIDEO:
        return VideoComponent;
      case TEXTO:
        return TextComponent;
      case DOC:
        return DocComponent;
      case IMAGE:
        return ImageComponent;
      case ANEXO:
        return AttachmentComponent;
      case EMBEDDED:
        return EmbeddedComponent;
      default:
        return TextComponent;
    }
  }

  async function saveModule({ chapterId, type, position, size, content }) {
    const moduleData = {
      chapterId: chapterId,
      contentType: type,
      textContent: content,
      positionX: position.x,
      positionY: position.y,
      width: size.width,
      height: size.height,
    };

    const { url } = courseApi.save_chapter_content;
    const request = axios.post(url, moduleData, {
      headers: { "Access-Control-Allow-Origin": "*", domain },
    });

    const resp = await request;

    if (resp.status >= 200 && resp.status < 300) {
    } else {
      console.error(`Save Content failed`);
    }
  }

  async function fetchModule() {
    const request = axios.get(courseApi.chapter_content.url, {
      params: {
        chapterContentId: id,
        type: type,
      },
      headers: { "Access-Control-Allow-Origin": "*", domain },
    });
    const response = await request;
    const data = response.data;

    if (response.status >= 200 && response.status < 300) {
      if (["ANEXO", "IMAGEM"].includes(data[0].contentType)) {
        data[0].content =
          data[0] && data[0].resource
            ? data[0]?.resource?.map((item) => ({
                ...item,
                RECURSOID: item.resourceId,
                QUADRANTEID: item.chapterContentId,
                NOME: item.name,
                LINK: item.link,
                EXTENSAO: item.link?.split(".")?.at(-1) ?? "url",
                isValid: true,
              }))
            : {
                RECURSOID: null,
                QUADRANTEID: null,
                NOME: null,
                LINK: null,
                EXTENSAO: null,
                isValid: true,
              };

        delete data[0]?.resource;
      }

      return data[0];
    } else {
      return {};
    }
  }
}

export default useModule;
