import { alpha, styled } from "@mui/material";
import { InputBase, Button } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const SearchBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  border: "solid 1px var(--secondary_color_light)",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  width: "100%",

  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function SearchBar({ onClickSearch }) {
  const [search, setSearch] = useState("");

  return (
    <SearchBarWrapper>
      <Button
        onClick={() => {
          onClickSearch(search);
        }}
      >
        <SearchIconWrapper>
          <SearchIcon color="secondary" />
        </SearchIconWrapper>
      </Button>
      <InputBase
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onClickSearch(search);
          }
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        placeholder="Pesquisar"
        inputProps={{ "aria-label": "search" }}
      />
    </SearchBarWrapper>
  );
}
