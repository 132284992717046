import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getChapterData = async (params) => {
  const res = await axios.middleware("/aluno.asmx/Dados_Capitulo", { params });
  return res[0].data[0];
};

export const useChapter = ({ config, chapterId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["chapterData", { chapterId }],
    queryFn: () => getChapterData({ capituloid: chapterId }),
  });
};
