import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import { baseUrl } from "../../../../../../shared/config";
import Carousel from "../../../../../blocks/Carousel";
import Card from "../../../../../blocks/Carousel/Card";
import CardImage from "../../../../../blocks/Carousel/Card/Image";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import LabelledSwitch from "../../../../../components/LabelledSwitch";
import PreviewMarker from "../../partials/PreviewMarker";
import { markView } from "../../../common/redux/CourseActionCreators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";

function ImageContent({
  contentId,
  sx = {},
  value,
  isEditing,
  setMandatory,
  auth,
  enrollment,
  chapters,
  markView,
  canBeMandatory = false,
}) {
  const { currentChapter } = useParams();
  const [imageOnView, setImageOnView] = useState(null);

  const handleCloseView = () => setImageOnView(null);

  const anexosVisualizados =
    chapters?.find((chapter) => chapter?.capituloid === Number(currentChapter))
      ?.views || [];

  const isAttachmentViewed = (resourceId) =>
    anexosVisualizados?.find(
      (attachmentViewed) => attachmentViewed.RECURSOID === resourceId
    );

  const handleOpenImage = (image) => {
    setImageOnView(image.url);

    if (
      !!image.url &&
      !!auth?.user?.alunoid &&
      !!auth?.token &&
      !!enrollment?.MATRICULAID &&
      !!currentChapter &&
      !!contentId &&
      !!image.resourceId
    ) {
      markView(
        auth.user.alunoid,
        auth.token,
        enrollment.MATRICULAID,
        currentChapter,
        contentId,
        image.resourceId
      );
    }
  };

  const urlPattern =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  const [images, setImages] = useState([]);

  useEffect(() => {
    try {
      setImages(
        value
          ?.filter((attachment) => attachment?.condition !== "removed")
          ?.map((attachment) => ({
            ...attachment,
            url: attachment?.link
              ? urlPattern.test(attachment?.link)
                ? attachment?.link
                : `${baseUrl}${attachment?.link}`
              : "",
          }))
      );
    } catch (error) {
      console.log(error, value);
    }
  }, [value]);
  const isCarousel = images?.length > 1;

  const theme = useTheme();
  const breakString = theme.breakpoints.up("md");
  const isDisplayMedium = useMediaQuery(breakString);
  if (!images) {
    return <></>;
  }
  return (
    <>
      {isCarousel ? (
        <Carousel>
          {images?.map((image, index) => (
            <Card key={`image-${image.url}`}>
              <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
                <Box
                  sx={{ width: "100%", height: "100%", cursor: "pointer" }}
                  onClick={() => handleOpenImage(image)}
                >
                  <CardImage isImage url={image.url} />
                </Box>
                {!isEditing && image.mandatory === "S" && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                    }}
                  >
                    <PreviewMarker
                      viewed={isAttachmentViewed(image.resourceId)}
                    />
                  </Box>
                )}
                {isEditing && canBeMandatory && (
                  <Box sx={{ position: "absolute", bottom: "0" }}>
                    <LabelledSwitch
                      label={isDisplayMedium ? "Obrigatório?" : null}
                      color="#6186F8"
                      labelPlacement="start"
                      fontWeight="700"
                      textTransform="uppercase"
                      fontSize="small"
                      sx={{
                        marginRight: "0",
                        marginLeft: "0.5rem",
                      }}
                      value={value[index].mandatory === "S"}
                      onChange={(event) =>
                        setMandatory(index, event.target.checked ? "S" : "N")
                      }
                    />
                  </Box>
                )}
              </Box>
            </Card>
          ))}
        </Carousel>
      ) : (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <Box
            component="img"
            sx={{
              ...sx,
              width: "100%",
              objectFit: "contain",
              position: "relative",
            }}
            alt=""
            src={images[0]?.url}
            onClick={() => handleOpenImage(images[0])}
          />
          {!isEditing && images[0]?.mandatory === "S" && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
              }}
            >
              <PreviewMarker
                viewed={isAttachmentViewed(images[0]?.resourceId)}
              />
            </Box>
          )}
        </Box>
      )}

      <Dialog open={!!imageOnView} onClose={handleCloseView}>
        <Box
          component="img"
          sx={{ ...sx, width: "100%", height: "100%", objectFit: "contain" }}
          alt=""
          src={imageOnView}
        />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ markView }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageContent);
