import { useState } from "react";
import VideoList from "../../../../../../../src/ui/blocks/VideoUploadModal/VideoList";
import { Box } from "@mui/material";
import FileInput from "../FileInput";

function VideoInput({ onChange, videoUrl }) {
  const [isListOpen, setIsListOpen] = useState(false);

  return (
    <Box sx={{ width: "90%", marginX: "auto" }}>
      <FileInput
        title="Escolha como incluir o video:"
        onClickUpload={() => setIsListOpen(true)}
        url={videoUrl}
        onSetUrl={onChange}
        uploadLabel="Selecionar"
      />

      <VideoList
        open={isListOpen}
        onSelect={(videoUrl) => {
          setIsListOpen(false);
          onChange(videoUrl);
        }}
        onClose={() => setIsListOpen(false)}
      />
    </Box>
  );
}

export default VideoInput;
