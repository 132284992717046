import { Button, Typography } from "@mui/material";
import Question from "../../../../EditCourse/Evaluation/Question";
import { useEffect, useState } from "react";

export default function Quiz({ evaluation, onSubmit, timeEnded, timeRunning }) {
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const isLastQuestion =
    currentQuestionIndex === evaluation?.questions?.length - 1;

  const currentQuestionHasAnswer = !!answers?.[currentQuestionIndex];

  const currentQuestion =
    evaluation?.questions?.length > 0
      ? evaluation.questions[currentQuestionIndex]
      : null;

  useEffect(() => {
    if (evaluation?.questions?.length > 0) {
      setAnswers(
        evaluation.questions.map((question) =>
          question.typeDescription == "ABERTA" &&
          question.category == "discursive"
            ? ""
            : null
        )
      );
      setCurrentQuestionIndex(0);
    }
  }, [evaluation]);

  useEffect(() => {
    if (timeEnded && timeRunning) {
      onSubmit(answers);
    }
  }, [timeEnded, timeRunning]);

  return (
    <>
      {!!currentQuestion && (
        <Question
          value={answers?.[currentQuestionIndex]}
          onChange={(event) => {
            const newValue = event?.target?.value ?? event;

            setAnswers((prev) => {
              const newAnswers = [...prev];
              newAnswers[currentQuestionIndex] = newValue;
              return newAnswers;
            });
          }}
          question={currentQuestion}
          index={currentQuestionIndex}
          evaluation={evaluation}
          exhibition
        />
      )}
      <Button
        sx={{ margin: "auto" }}
        color="primary"
        variant={isLastQuestion ? "contained" : "outlined"}
        onClick={() => {
          if (isLastQuestion) {
            onSubmit(answers);
          } else {
            setCurrentQuestionIndex((prev) => prev + 1);
          }
        }}
        disabled={!currentQuestionHasAnswer || timeEnded}
      >
        <Typography>
          {timeEnded
            ? "O tempo acabou"
            : isLastQuestion
            ? "Enviar respostas"
            : "Próxima pergunta"}
        </Typography>
      </Button>
    </>
  );
}
