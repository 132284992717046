import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { QuestionComponents } from "./questionCategories";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TextComponent from "../../../ui/components/TextComponent";
import RichText from "../../../../../components/RichText";

export default function Question({
  error,
  question,
  index,
  evaluation,
  editingQuestions = false,
  editable = false,
  exhibition = false,
  value = null,
  questionTypes,
  canSave = true,
  onChange = () => {},
  setEvaluation = () => {},
  removeQuestion = () => {},
  editChoices = () => {},
  removeChoice = () => {},
  addChoice = () => {},
  onSaveQuestion = () => {},
  setEdited = () => {},
}) {
  const theme = useTheme();

  const inEdit = editable && question.inEditing;

  const questionsList =
    evaluation?.questions?.filter((question) => !question?.remove) ?? [];

  const totalQuestions = questionsList?.length ?? 0;

  const currentQuestionNumber =
    questionsList?.indexOf(evaluation?.questions?.[index]) !== -1
      ? questionsList?.indexOf(evaluation?.questions?.[index]) + 1
      : 0;

  return (
    <Box
      sx={{
        borderColor: error
          ? theme.palette.error.main
          : inEdit
          ? theme.palette.primary.main
          : "#F3F3F4",
        borderWidth: "1px",
        borderRadius: "10px",
        padding: "1rem",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "700",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {`Questão ${currentQuestionNumber} de ${totalQuestions}`}
          {question.mandatory == "S" && (
            <>
              *
              <Typography fontWeight="600" fontSize={"9px"} variant="caption">
                Questão obrigatória
              </Typography>
            </>
          )}
        </Typography>
        {inEdit && (
          <>
            {evaluation.questions[index].category !== "questions" && (
              <FormControl>
                <InputLabel id="question-type">Tipo de questão</InputLabel>
                <Select
                  id="question-type"
                  size="small"
                  label="Tipo de questão"
                  value={questionTypes.findIndex((item) => {
                    if (!question.template && question.listOptions.length) {
                      if (
                        question.listOptions.length == 2 &&
                        question.listOptions[0].optionDescription ===
                          "<p>Verdadeiro</p>" &&
                        question.listOptions[1].optionDescription ===
                          "<p>Falso</p>"
                      ) {
                        return item.label === "Verdadeiro ou Falso";
                      }
                      if (
                        question.listOptions.length == 2 &&
                        question.listOptions[0].optionDescription ===
                          "<p>Sim</p>" &&
                        question.listOptions[1].optionDescription ===
                          "<p>Não</p>"
                      ) {
                        return item.label === "Verdadeiro ou Falso";
                      }
                      return item.label === "Múltipla escolha";
                    }
                    return item.template === question.template;
                  })}
                  onChange={(event) =>
                    setEvaluation((prev) => {
                      const newTypeIndex = event.target.value;
                      const newType = questionTypes[newTypeIndex];
                      if (newType?.type) {
                        prev.questions[index] = {
                          ...prev.questions[index],
                          category: newType.category,
                          listOptions: [
                            ...(newType.template == "oneToFiveStars" || // SEM ESSA CONDIÇÃO, A OPÇÃO PODE QUEBRAR O REACT QUILL
                            newType.template == "oneToTenStars"
                              ? [
                                  ...prev.questions[index].listOptions
                                    .map((opt) => {
                                      if (!!opt.id) {
                                        return { ...opt, remove: true };
                                      }
                                    })
                                    .filter((opt) => {
                                      return opt !== undefined;
                                    }),
                                ]
                              : []),
                            ...newType.listOptions,
                          ],
                          template: newType.template,
                          type: newType.type,
                        };
                      } else {
                        prev.questions[index] = {
                          ...prev.questions[index],
                          category: newType.category,
                        };
                      }

                      prev.questions[index].label = newType.label;
                      console.log({ prev });

                      return { ...prev };
                    })
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      minWidth: "10rem",
                    },
                  }}
                >
                  {questionTypes.map((item, index) => (
                    <MenuItem value={index}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {!!question.weight && (
              <FormControl>
                <InputLabel id="question-weight">Peso da questão</InputLabel>
                <Select
                  id="question-weight"
                  size="small"
                  label="Peso da questão"
                  value={question.weight}
                  onChange={(event) =>
                    setEvaluation((prev) => {
                      const newWeight = event.target.value;

                      prev.questions[index].weight = newWeight;

                      return { ...prev };
                    })
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      minWidth: "10rem",
                    },
                  }}
                >
                  {[1, 2, 3, 4, 5].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
        {editable && !editingQuestions && (
          <Box>
            <IconButton
              onClick={() =>
                setEvaluation((prev) => {
                  prev.questions[index].inEditing = true;
                  prev.questions[index].previousState = structuredClone(
                    prev.questions[index]
                  );
                  return { ...prev };
                })
              }
            >
              <BorderColorOutlinedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "24px" }}
              />
            </IconButton>
            <IconButton onClick={() => removeQuestion(index)}>
              <DeleteOutlinedIcon color="error" sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {inEdit && evaluation.questions[index].category !== "questions" && (
        <FormLabel>Enunciado:</FormLabel>
      )}
      {evaluation.questions[index].category !== "questions" && (
        <Box
          sx={{
            border: inEdit ? "2px solid" : "unset",
            borderColor: theme.palette.grey["500"],
          }}
        >
          <RichText
            value={question.statement}
            onChange={(newValue) =>
              setEvaluation((prev) => {
                prev.questions[index].statement = newValue;
                return { ...prev };
              })
            }
            isEditing={inEdit}
            choiseComponent={inEdit ? false : true}
          />
        </Box>
      )}
      <QuestionComponents
        typeIndex={questionTypes?.findIndex(
          (item) => item.label === question.label
        )}
        exhibition={editable || exhibition}
        value={
          editable
            ? question?.category === "discursive"
              ? "Reposta..."
              : question?.answerTemplate
            : value
        }
        showAnswer={
          question?.category === "multipleChoice" &&
          question?.type === "evaluation" &&
          editable
        }
        onChange={
          editable
            ? (event) => {
                if (event?.target?.value) {
                  setEvaluation((prev) => {
                    if (typeof event.target.value === "object") {
                      prev.questions[index] = event.target.value;
                      // onSaveQuestion(index);
                    } else {
                      prev.questions[index].answerTemplate = Number(
                        event.target.value
                      );
                    }

                    return { ...prev };
                  });
                }
              }
            : onChange
        }
        category={question.category}
        type={question?.type}
        template={question?.template}
        inEditing={inEdit}
        canEditText={
          question.template !== "trueOrFalse" && question.template !== "yesOrNo"
        }
        onChangeChoices={(choiceIndex, newValue) =>
          editChoices(index, choiceIndex, newValue)
        }
        onRemoveChoice={(choiceIndex) => removeChoice(index, choiceIndex)}
        onAddChoice={() => addChoice(index)}
        listOptions={question?.listOptions ?? []}
        fieldType={question?.fieldType}
        question={question}
      />
      {inEdit && (
        <Box
          sx={{
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            sx={{ height: "fit-content" }}
            size="small"
            variant="contained"
            color="error"
            onClick={() => {
              setEvaluation((prev) => {
                if (prev.questions[index].previousState) {
                  prev.questions[index] = {
                    ...prev.questions[index].previousState,
                  };
                  prev.questions[index].inEditing = false;
                } else {
                  prev.questions.splice(index, 1);
                }
                return { ...prev };
              });
              setEdited(false);
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              Cancelar
            </Typography>
          </Button>
          <Button
            sx={{ height: "fit-content", marginLeft: "12px" }}
            size="small"
            variant="outlined"
            color="success"
            disabled={!canSave}
            onClick={() => onSaveQuestion(index)}
          >
            <Typography
              sx={{
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              Salvar Edições
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
