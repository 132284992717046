import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Survey from "../SurveyPage/Survey";
import {
  FINISH_ATTEMPT,
  GET_FULL_RESEARCH,
  LOGIN_SURVEYS_LIST,
  START_ATTEMPT,
  listaPesquisasLogin,
} from "../../../shared/store/survey";
import Loading from "../../components/Loading";
import { useEffect, useState } from "react";

import { Close, InfoOutlined } from "@mui/icons-material";
import DatesLabel from "../../components/DatesLabel";
import moment from "moment";
import ImageWithBackdrop from "../../components/ImageWithBackdrop";
import { baseUrl } from "../../../shared/config";
import RichText from "../../components/RichText";

function LoginSurveys({
  setCanLogIn,
  auth,
  surveys,
  requestsStatus,
  listaPesquisasLogin,
}) {
  const [isSurveyListOpen, setIsSurveyListOpen] = useState(false);
  const [currentSurveyId, setCurrentSurveyId] = useState(null);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  const isSomeSurveyLoginAccessible = surveys?.some(
    (survey) => survey.modo_acesso === "LOGON"
  );

  const isSomeSurveyMandatory = surveys?.some(
    (survey) => survey.OBRIGATORIA === "S"
  );
  surveys?.some((survey) => survey.OBRIGATORIA === "S");
  useEffect(() => {
    if (auth?.token) {
      listaPesquisasLogin(auth.user.alunoid, auth.token);
    }
  }, [auth?.token, listaPesquisasLogin]);

  useEffect(() => {
    if (isSomeSurveyLoginAccessible) {
      setIsSurveyListOpen(true);
    } else if (isSomeSurveyLoginAccessible != null) {
      setCanLogIn(true);
    }
  }, [surveys, isSomeSurveyMandatory]);

  const handleCancel = () => setIsSurveyListOpen(false);
  const handleClose = () => {
    if (isSomeSurveyMandatory) {
      alert(
        "Você não poderá acessar o sistema antes de responder a todas as pesquisas obrigatórias"
      );
    } else {
      setIsSurveyListOpen(false);
      setCanLogIn(true);
    }
  };

  const handleFinish = () => {
    setCurrentSurveyId(null);
    listaPesquisasLogin(auth.user.alunoid, auth.token);
  };

  return (
    <>
      <Dialog
        // sx={{
        //   [theme.breakpoints.down("md")]: {
        //     ".MuiPaper-elevation": {
        //       // height: "80vh",
        //       width: "100%",
        //       // margin: "1rem 0px",
        //     },
        //   },
        // }}
        open={!!auth?.user?.alunoid && isSurveyListOpen}
      >
        <DialogTitle>Você tem pesquisas a serem respondidas</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            {surveys
              ?.filter((survey) => {
                return survey.modo_acesso == "LOGON";
              })
              .map((survey) => (
                <Accordion sx={{ backgroundColor: "white" }}>
                  <AccordionSummary
                    sx={{
                      padding: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",

                      alignItems: "center",
                    }}
                  >
                    <IconButton sx={{ padding: 0 }} color="info">
                      <InfoOutlined />
                    </IconButton>
                    <Box
                      sx={{
                        marginLeft: 1,
                        display: "flex",
                        flexDirection: "column",
                        "& > span:last-child": { color: "red" },
                        alignItems: "flex-start",
                        justifyContent: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        fontWeight="600"
                      >
                        {survey?.TITULO}
                      </Typography>
                      <Typography
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        Expira em: &nbsp;
                        <DatesLabel
                          labelPosition="top"
                          orientation="horizontal"
                          hideIcon
                          datesArray={[
                            {
                              date: moment(
                                survey.DTFIM.replace("T", " "),
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD/MM/YYYY HH:mm:ss"),
                            },
                          ]}
                        />
                      </Typography>
                      {survey.OBRIGATORIA === "S" && <span>OBRIGATÓRIA</span>}
                    </Box>
                    <AccordionActions sx={{ marginLeft: "auto " }}>
                      <Button
                        variant="contained"
                        onClick={() => setCurrentSurveyId(survey?.PESQUISAID)}
                      >
                        Responder
                      </Button>
                    </AccordionActions>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={1}>
                      <Box
                        sx={{
                          aspectRatio: "16 / 9",
                          width: "100%",
                          height: "auto",
                        }}
                      >
                        <ImageWithBackdrop
                          url={baseUrl + "/Membros/recursos/" + survey.FOTO}
                        />
                      </Box>
                      <RichText value={survey?.ORIENTACOES} />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar login
          </Button>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={handleClose}
            disabled={isSomeSurveyMandatory}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          ".MuiPaper-elevation": {
            height: "80vh",
          },
        }}
        fullWidth={true}
        maxWidth={"lg"}
        fulScreen={isScreenSmall}
        open={!!currentSurveyId}
        onClose={() => {
          setCurrentSurveyId(null);
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 3, right: 3 }}
          onClick={() => {
            setCurrentSurveyId(null);
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Survey
            login
            fallbackStudentId={auth?.user?.alunoid}
            surveyId={currentSurveyId}
            onFinishAttemptSuccess={handleFinish}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={
          requestsStatus[LOGIN_SURVEYS_LIST] === "loading" ||
          requestsStatus[GET_FULL_RESEARCH] === "loading" ||
          requestsStatus[START_ATTEMPT] === "loading" ||
          requestsStatus[FINISH_ATTEMPT] === "loading"
        }
      >
        <Box sx={{ position: "relative", width: "5rem", height: "5rem" }}>
          <Loading />
        </Box>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  surveys: state.survey.loginSurveys,
  requestsStatus: state.survey.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listaPesquisasLogin,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginSurveys);
