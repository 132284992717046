import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const finishChapter = async ({ courseId, chapterId, enrollmentId }) => {
  const bodyFormData = new FormData();

  bodyFormData.append(
    "dados",
    `[{
    "alunoid": ${_cookies.getItem("auth").user.alunoid},
    "disciplinaid": ${courseId},
    "matriculaid": ${enrollmentId},
    "capituloid":${chapterId},
  }]`
  );
  const res = await axios.middleware.post(
    "/aluno.asmx/Finaliza_Capitulo",
    bodyFormData
  );
  return res[0];
};

export const useFinishChapter = ({ config } = {}) => {
  return useMutation({ ...config, mutationFn: finishChapter });
};
