import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  TablePagination,
  TextField,
} from "@mui/material";
import GridList from "../../../ui/components/List/GridList";
import { useVimeoVideos } from "../api/getVimeoVideos";
import GridListItem from "../../../ui/components/List/GridListItem";
import { useState } from "react";
import VimeoVideoUploader from "./VimeoVideoUploader";
import { Delete, Check } from "@mui/icons-material";
import DeleteVimeoVideo from "./DeleteVimeoVideo";

export default function VimeoVideos({ onSelectVideo = () => {} }) {
  const [myVideos, setMyVideos] = useState(false);
  const [page, setPage] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(25);
  const vimeoVideos = useVimeoVideos({
    params: { myVideos, page: page + 1, itensPerPage, myVideos },
  });

  const parseVideoUrl = (video) => {
    return video && video.video_id
      ? video?.video_id.search(/panda/) > -1
        ? video?.video_id
        : `https://player.jmvstream.com/aOknaQpB9EbVf8wSwHOnHoPYHWHRPO/${video?.video_id}`
      : null;
  };
  const handleSelectVideo = (video) => () => {
    onSelectVideo(parseVideoUrl(video));
  };

  const handleChangeVideoFilter = (event) => {
    setMyVideos(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newValue) => {
    setPage(parseInt(newValue));
  };

  const handleChangeItensPerPage = (event) => {
    setItensPerPage(event.target.value);
    setPage(0);
  };

  if (vimeoVideos.isFetching) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: { sm: "initial", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <VimeoVideoUploader />
        <Box sx={{ display: { sm: "initial", md: "flex" } }}>
          <TextField select value={myVideos} onChange={handleChangeVideoFilter}>
            <MenuItem value={true}>Meus vídeos</MenuItem>
            <MenuItem value={false}>Todos os vídeos</MenuItem>
          </TextField>
          <TablePagination
            labelDisplayedRows={({ from, to, count, page }) => {
              return `${from}–${to} de ${count !== -1 ? count : ` ${to}`}`;
            }}
            labelRowsPerPage="Items por página"
            component="div"
            count={vimeoVideos.data?.total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={itensPerPage}
            onRowsPerPageChange={handleChangeItensPerPage}
          />
        </Box>
      </Box>
      {vimeoVideos.isLoading ? (
        <CircularProgress />
      ) : (
        <GridList>
          {vimeoVideos.data?.data?.map((video) => {
            return (
              <GridListItem
                CardProps={{
                  sx: {
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  },
                }}
                disabled
                ActionAreaProps={{
                  disabled: true,
                }}
                imageUrl={video.picture || "erro"}
                label={video.name}
                footer={
                  <Box
                    sx={{
                      paddingX: 1,
                    }}
                  >
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={handleSelectVideo(video)}
                        disabled={!video.video_id || !video.id}
                        startIcon={<Check />}
                      >
                        Selecionar
                      </Button>
                      <DeleteVimeoVideo videoId={video.id} />
                    </Box>
                  </Box>
                }
              />
            );
          })}
        </GridList>
      )}
    </Box>
  );
}
