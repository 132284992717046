import { Box, Typography } from "@mui/material";
import { useFinishChapter } from "../api/finishChapter";

export default function LinkChapter({
  enrollmentId,
  url,
  chapterId,
  courseId,
}) {
  const finishChapter = useFinishChapter();

  const handleClick = () => {
    finishChapter.mutate({
      courseId,
      chapterId,
      enrollmentId,
    });

    window.open(url, "_blank").focus();
  };

  return (
    <Box
      underline="hover"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography color="grey" underline="always">
        O conteúdo desse capítulo é externo.
      </Typography>
      <Typography
        sx={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={handleClick}
        color="grey"
        underline="always"
      >
        Clique aqui pra ser redirecionado
      </Typography>
    </Box>
  );
}
