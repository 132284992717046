import axios from "axios";
import { api, courseApi } from "../../../../../shared/api";

import {
  certificate,
  certificateDefault,
  getClient,
} from "../../../../../shared/config";
import {
  COURSE_DADOS_DISCIPLINA,
  COURSE_CERTIFICADO_PROPRIO,
  COURSE_CLEANUP,
  COURSE_CLEAR_CERTIFICATE,
  COURSE_LISTA_CAPITULOS,
  COURSE_LOGON,
  COURSE_LOGOUT,
  COURSE_SET_CURRENT_COURSE,
  COURSE_STUDENT_ENROLLMENT_FETCHED,
  CHANGE_STATUS_COURSE,
  COURSE_LIST_VIEWED,
  COURSE_TOGGLE_LIBERADO,
} from "./CourseReducer";

export const addChapter = ({ type }) => {
  return async (dispatch) => {};
};

// other
const setCertificateUrl = async (disciplinaid) => {
  const certificateUrl = certificate.replace(/{disciplinaid}/, disciplinaid);
  try {
    const res = await fetch(certificateUrl);
    return res.status === 200 && res.redirected === false
      ? certificateUrl
      : certificateDefault;
  } catch (error) {
    console.log("setCertificateUrl error", error);
    return certificateDefault;
  }
};

const fetchTeachers = async (disciplinaid, token) => {
  try {
    const res = await fetch(`${api.professores}?disciplinaid=${disciplinaid}`, {
      method: "get",
      headers: { token },
    });
    const json = await res.json();
    const teachers = json[0].data;
    return teachers;
  } catch (error) {
    console.log("fetchTeachers error", error);
    return null;
  }
};

export const getStudentEnrollmentData = ({ auth, courseId }) => {
  return async (dispatch) => {
    const { studentId, token } = auth;
    const res = await fetch(
      `${api.busca_matricula_no_curso}?alunoid=${studentId}&disciplinaid=${courseId}`,
      {
        method: "get",
        headers: { token },
      }
    );
    try {
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        if (!json || json.length === 0) {
          dispatch({ type: COURSE_STUDENT_ENROLLMENT_FETCHED, payload: null });
        }

        const status = json[0].status;

        if (status === 403) {
          dispatch({ type: COURSE_STUDENT_ENROLLMENT_FETCHED, payload: null });
          return null;
        }
        const enrollment = json[0].data[0];
        dispatch({
          type: COURSE_STUDENT_ENROLLMENT_FETCHED,
          payload: enrollment,
        });
        return enrollment.MATRICULAID;
      } else {
        dispatch({ type: COURSE_STUDENT_ENROLLMENT_FETCHED, payload: null });
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COURSE_STUDENT_ENROLLMENT_FETCHED, payload: null });
      throw error;
    }
  };
};

export const courseData = (disciplinaid, token) => {
  return async (dispatch) => {
    const apiUrl =
      disciplinaid > 900000
        ? api.dados_disciplina_convenio
        : api.dados_disciplina;
    try {
      const header = new Headers();
      //+   header.append("access-control-allow-origin", "*");
      header.append("token", token);
      const res = await fetch(`${apiUrl}?disciplinaid=${disciplinaid}`, {
        method: "get",
        headers: header,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        if (!json[0].data) {
          dispatch({ type: COURSE_DADOS_DISCIPLINA, details: null });
        }
        const details = json[0].data[0];
        details.teachers = await fetchTeachers(disciplinaid, token);
        dispatch({ type: COURSE_DADOS_DISCIPLINA, details });
        return details;
      } else {
        dispatch({ type: COURSE_DADOS_DISCIPLINA, details: null });
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("courseData error", error);
      return null;
    }
  };
};

export const listChapters = (matriculaid, disciplinaid, token) => {
  return async (dispatch) => {
    if (!matriculaid) return;

    const apiUrl =
      disciplinaid > 900000
        ? api.lista_capitulos_convenio
        : api.lista_capitulos;
    try {
      const header = new Headers();
      header.append("token", token);
      const res = await fetch(`${apiUrl}?matriculaid=${matriculaid}`, {
        method: "get",
        headers: header,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        if (!json || json.length === 0) {
          dispatch({ type: COURSE_CLEANUP });
        }
        const chapters = json?.[0]?.data || [];
        if (chapters?.[0]?.disciplinaid == disciplinaid) {
          dispatch({ type: COURSE_LISTA_CAPITULOS, chapters: chapters });
        } else {
          dispatch({ type: COURSE_CLEANUP });
        }
      } else {
        dispatch({ type: COURSE_CLEANUP });
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log({ error });
      throw new Error(error);
    }
  };
};

export const listChaptersProf = (disciplinaid, token) => {
  return async (dispatch) => {
    const apiUrl = api.lista_capitulos_prof;

    try {
      const header = new Headers();
      //  header.append("access-control-allow-origin", "*");
      header.append("token", token);
      const res = await fetch(`${apiUrl}?disciplinaid=${disciplinaid}`, {
        method: "get",
        mode: "cors",
        headers: header,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        if (!json || json.length === 0) {
          dispatch({ type: COURSE_LISTA_CAPITULOS, chapters: null });
        }
        const chapters = json?.[0]?.data || [];

        dispatch({ type: COURSE_LISTA_CAPITULOS, chapters: chapters });
      } else {
        dispatch({ type: COURSE_CLEANUP, chapters: [] });
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log({ error });
      throw new Error(error);
    }
  };
};

export const markView = (
  studentId,
  token,
  enrollId,
  chapterId,
  contentId,
  resourceId = null
) => {
  return async (dispatch) => {
    try {
      const bodyFormData = new FormData();

      const payload = {
        alunoid: studentId,
        matriculaId: enrollId,
        quadranteid: contentId,
        ...(resourceId ? { recursoid: resourceId } : {}),
      };

      bodyFormData.append("dados", JSON.stringify([payload]));

      await axios({
        url: api.marca_visualizacao,
        method: "post",
        headers: { token },
        data: bodyFormData,
      });

      listViewed(chapterId, enrollId, token)(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
};

export const listViewed = (chapterId, enrollId, token) => {
  return async (dispatch) => {
    try {
      const data = await axios({
        url: api.lista_visualizacoes,
        method: "get",
        headers: { token },
        params: {
          matriculaid: enrollId,
          capituloid: chapterId,
        },
      }).then((res) => res.data[0].data);

      dispatch({ type: COURSE_LIST_VIEWED, chapterId, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const mountCourse = ({ courseId, auth, isApp, profile }) => {
  return async (dispatch, getState) => {
    const { studentId, token } = auth;

    try {
      const enrollId = await getStudentEnrollmentData({ courseId, auth })(
        dispatch
      );

      courseData(courseId, token)(dispatch);

      if (profile?.isProfessor && !enrollId) {
        listChaptersProf(courseId, token)(dispatch);
      } else {
        listChapters(enrollId, courseId, token)(dispatch);
      }
    } catch (error) {
      throw error;
    }
  };
};

export const courseLogon = (token, alunoid, disciplinaid, isApp) => {
  return async (dispatch) => {
    let body = new FormData();
    body.append(
      "dados",
      `[{"alunoid":${alunoid},"disciplinaid":"${disciplinaid}","ISAPP":"${isApp}"}]`
    );
    try {
      const res = await fetch(api.logon, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      const logonid = json[0].data.LOGONID;
      dispatch({ type: COURSE_LOGON, logonid });
    } catch (error) {
      dispatch({ type: COURSE_LOGON, logonid: null });
      throw new Error(error);
    }
  };
};

export const changeEditMode = (course) => {
  return async (dispatch) => {
    const domain = getClient();
    try {
      const res = await fetch(courseApi.put_course.url, {
        method: courseApi.put_course.method,
        headers: {
          domain,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(course),
      });
      const json = await res.json();
      dispatch({ type: CHANGE_STATUS_COURSE, updatedCourse: json });
    } catch (error) {
      dispatch({ type: CHANGE_STATUS_COURSE, logonid: null });
      throw new Error(error);
    }
  };
};

export const logout = (logonid, token) => {
  return async (dispatch) => {
    let body = new FormData();
    body.append("dados", `[{"logonid":${logonid}}]`);
    try {
      // const res =
      await fetch(api.logout, {
        method: "post",
        headers: { token },
        body,
      });
      // const json = await res.json();
      dispatch({ type: COURSE_LOGOUT, logonid: null });
    } catch (error) {
      dispatch({ type: COURSE_LOGOUT, logonid: null });
      throw new Error(error);
    }
  };
};

export const certificadoProprio = (matriculaid, disciplinaid, token) => {
  return async (dispatch) => {
    const apiUrl =
      disciplinaid > 900000
        ? api.certificado_convenio
        : api.certificado_proprio;
    try {
      const res = await fetch(`${apiUrl}?matriculaid=${matriculaid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const certificate = json[0].data[0][0];
        const certificateUrl = await setCertificateUrl(disciplinaid);
        certificate.certificateUrl = certificateUrl;
        certificate.disciplinaid = disciplinaid;
        certificate.id = `${matriculaid}${disciplinaid}`;
        dispatch({ type: COURSE_CERTIFICADO_PROPRIO, certificate });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      // dispatch({ type: COURSE_CERTIFICADO_PROPRIO, certificate: null });
      console.log("certificadoProprio error", error, matriculaid, disciplinaid);
      throw new Error(error);
    }
  };
};

export const alterarLiberacao = (token, disciplinaid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.alterar_liberacao}?disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        if (json[0].message === "Success") {
          dispatch({ type: COURSE_TOGGLE_LIBERADO });
        }
      }
    } catch (error) {
      console.log("alterar liberacao error", error);
      throw new Error(error);
    } finally {
    }
  };
};

export const courseCleanUp = () => {
  return { type: COURSE_CLEANUP };
};

export const clearCertificate = () => {
  return { type: COURSE_CLEAR_CERTIFICATE };
};

export const setCurrentCourse = (currentCourse) => {
  return { type: COURSE_SET_CURRENT_COURSE, currentCourse };
};
