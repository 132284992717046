import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CARD_ASPECT_RATIO, RELATIVE_CARD_HEIGHT } from "./Card/constants";
import CarouselScrollButtons from "./ScrollButtons";

export default function Carousel({ children }) {
  const containerRef = useRef();

  const containerElement = containerRef?.current || null;

  const [itemOnCenter, setItemOnCenter] = useState(0);

  const [deltaScroll, setDeltaScroll] = useState(1);

  useEffect(() => {
    if (!!containerElement) {
      const observer = new ResizeObserver((entries) => {
        const containerHeight = entries[0].contentRect.height;

        const cardHeight = RELATIVE_CARD_HEIGHT * containerHeight;

        const cardWidth = CARD_ASPECT_RATIO * cardHeight;

        const newDeltaScroll = cardWidth / RELATIVE_CARD_HEIGHT;

        setDeltaScroll(newDeltaScroll);
      });

      observer.observe(containerElement);

      return () => observer.unobserve(containerElement);
    }
  }, [containerElement]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          width: "100%",
          height: "90%",
          overflow: "hidden",
          scrollSnapType: "x proximity",
          paddingX: "50%",
          alignItems: "center",
          scrollBehavior: "smooth",
        }}
        onScroll={(event) => {
          const newCentered = Math.round(event.target.scrollLeft / deltaScroll);

          if (itemOnCenter !== newCentered) {
            setItemOnCenter(newCentered);
          }
        }}
        onWheel={(event) =>
          containerElement.scroll({
            left: containerElement.scrollLeft + event.deltaY,
          })
        }
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            sx: {
              ...(itemOnCenter === index
                ? {
                    transform: "scale(1.1)",
                    zIndex: 10,
                    "box-shadow":
                      "25px 0px 20px -20px rgba(0,0,0,0.45),-25px 0px 20px -20px rgba(0,0,0,0.45)",
                  }
                : {
                    transform: "scale(1)",
                    zIndex: 0,
                    cursor: "pointer",
                  }),
            },
            onClick: () => {
              if (itemOnCenter !== index) {
                containerElement.scroll({ left: index * deltaScroll });
              }
            },
          })
        )}
      </Box>
      <CarouselScrollButtons
        currentItem={itemOnCenter}
        totalItems={children?.length || 0}
        containerRef={containerElement}
        deltaScroll={deltaScroll}
      />
    </Box>
  );
}
