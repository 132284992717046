import DirectAccessChapter from "../../features/chapters/components/DirectAccessChapter";
import RouterBuilder from "./RouterBuilder";
import { lazy } from "react";
// import CoursePage from "../../ui/pages/Course/pages/CoursePage";
// import EditCourse from "../../ui/pages/Course/pages/EditCourse";
// import ChapterPage from "../../ui/pages/Course/pages/ChapterPage";
// import VideoList from "../../ui/blocks/VideoUploadModal/VideoList";

const CoursePage = lazy(() => import("../../ui/pages/Course/pages/CoursePage"));
const EditCourse = lazy(() => import("../../ui/pages/Course/pages/EditCourse"));
const ChapterPage = lazy(() =>
  import("../../ui/pages/Course/pages/ChapterPage")
);
const VideoList = lazy(() =>
  import("../../ui/blocks/VideoUploadModal/VideoList")
);

const routeFactory = RouterBuilder.routeFactory;

const routes = [
  routeFactory("test", "/test", VideoList),
  routeFactory("curso", "curso/:id/:disciplinaid", CoursePage),
  routeFactory("capitulo", ":currentChapter", ChapterPage, "curso"),
  routeFactory(
    "standalone-capitulo",
    "capitulo/:capituloid",
    DirectAccessChapter
  ),
  routeFactory(
    "course-edition",
    "curso/edicao/:type/:disciplinaid/",
    EditCourse
  ),
  routeFactory(
    "course-edition-id",
    "curso/edicao/:type/:disciplinaid/:id",
    EditCourse
  ),
];

const CourseRoutes = new RouterBuilder(routes).nestedRoutes;

export default CourseRoutes;
