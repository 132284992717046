import { InsertDriveFileOutlined, Link } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

const Icon = ({ extension }) => (
  <SvgIcon sx={{ margin: "10px", fontSize: "25px", color: "var(--primary_color_main)" }}>
    {extension === "URL" ? (
      <Link />
    ) : (
      <>
        <InsertDriveFileOutlined />
        <rect fill="white" width="24" height="9" x="0" y="10"></rect>
        <text
          x="12"
          y="16"
          width="22"
          textAnchor="middle"
          fontSize="8px"
          fontWeight="700"
          color="var(--primary_color_main)"
        >
          {extension.toUpperCase()}
        </text>
      </>
    )}
  </SvgIcon>
);

export default Icon;
