import Box from "@mui/material/Box";
import AttachmentCard from "./Card";

function AttachmentContent({
  value,
  removeAttachment,
  isEditing,
  setMandatory,
  contentId,
  canBeMandatory = false,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      {typeof value != "string" &&
        value?.length > 0 &&
        value
          .map((attachment, index) => {
            if (attachment?.condition !== "removed") {
              return (
                <AttachmentCard
                  contentId={contentId}
                  key={`file-${attachment.resourceId}`}
                  attachment={attachment}
                  index={index}
                  isEditing={isEditing}
                  removeAttachment={removeAttachment}
                  mandatory={attachment.mandatory}
                  setMandatory={(value) => setMandatory(index, value)}
                  canBeMandatory={canBeMandatory}
                />
              );
            }

            return null;
          })
          .filter((child) => child != null)}
    </Box>
  );
}

export default AttachmentContent;
