import {
  Box,
  Grid,
  Link,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
  Tooltip,
} from "@mui/material";
import ImageWithBackdrop from "../ImageWithBackdrop";
export default function GridListItem({
  label = null,
  subLabel = null,
  additionalContent = null,
  imageUrl = null,
  footer = null,
  ActionAreaProps = {},
  LabelProps = {},
  SubLabelProps = {},
  CardProps = {},
  CardContentProps = {},
  CardMediaProps = {},
}) {
  return (
    <Grid item>
      <Card sx={{ height: "100%" }} {...CardProps}>
        <CardActionArea {...ActionAreaProps}>
          {!!imageUrl && (
            <CardMedia {...CardMediaProps}>
              <Box
                sx={{
                  aspectRatio: "16 / 9",
                  width: "100%",
                  // height: "fit-content",
                }}
              >
                <ImageWithBackdrop url={imageUrl} />
              </Box>
            </CardMedia>
          )}
          {(label || subLabel) && (
            <CardContent {...CardContentProps}>
              <Tooltip title={label}>
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "500" }}
                  noWrap
                  gutterBottom
                  // component="h6"
                  color="primary"
                  {...LabelProps}
                >
                  {label}
                </Typography>
              </Tooltip>
              <Tooltip title={subLabel}>
                <Typography
                  noWrap
                  variant="caption"
                  color="text.secondary"
                  {...SubLabelProps}
                >
                  {subLabel}
                </Typography>
              </Tooltip>
              {additionalContent}
            </CardContent>
          )}
        </CardActionArea>
        {footer}
      </Card>
    </Grid>
  );
}
