import {
  RichTreeView,
  SimpleTreeView,
  TreeItem,
  useTreeItemState,
} from "@mui/x-tree-view";
import { Typography, Box, IconButton, Checkbox, Icon } from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import _ from "lodash";
import { flatListToNested, getNestedChildren } from "./utils/flatListToNested";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  Close,
} from "@mui/icons-material";

const TreeItemContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    nodeId,
    itemId,
    label,
    iconProp,
    expansionIcon,
    displayIcon,
  } = props;
  const { disabled, expanded, handleExpansion, preventSelection, ...rest } =
    useTreeItemState(itemId);
  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.disabled]: disabled,
      })}
      // onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

function CustomTreeItem(props) {
  return <TreeItem ContentComponent={TreeItemContent} {...props} />;
}

function Branch({ item, disabled, onSelect }) {
  const isSomeChildrenSelected = item?.children?.some(
    (child) => child.selected
  );
  const isSomeChildrenNotSelected = item?.children?.some(
    (child) => !child.selected
  );
  const itemIsIndetermined =
    isSomeChildrenSelected && isSomeChildrenNotSelected;
  return (
    <CustomTreeItem
      itemId={item?.id}
      label={
        <>
          <IconButton
            disabled={disabled}
            onClick={(event) => {
              onSelect(item, !item.selected);
            }}
          >
            {item.selected ? (
              <CheckBox />
            ) : itemIsIndetermined ? (
              <IndeterminateCheckBox />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </IconButton>
          {item?.label}
        </>
      }
    >
      {item?.children?.map((child) => {
        return <Branch item={child} onSelect={onSelect} disabled={disabled} />;
      })}
    </CustomTreeItem>
  );
}
export default function TreeSelector({
  list,
  setList,
  onDirty = () => {},
  sx,
  disabled = false,
  ...rest
}) {
  function selectItemAndChildren(item, value) {
    setList((prev) => {
      const foo = prev;
      foo[foo.findIndex((obj) => obj.id == item.id)] = {
        ...item,
        selected: value,
      };

      return [...foo];
    });
    if (!item.children) {
      return;
    }
    return item.children.map((child) => selectItemAndChildren(child, value));
  }

  function correctParentState(item, value) {
    const { parentId } = item;
    if (parentId == null) {
      return;
    }
    const parent = list.find((foo) => foo.id == parentId);

    const siblings = list.filter(
      (foo) => foo.parentId == parentId && foo.id != item.id
    );
    const isSomeSiblingNotChecked = siblings.some((foo) => !foo.selected);
    const parentNewSelectedValue = !isSomeSiblingNotChecked && value;

    setList((prev) => {
      const temp = prev;
      temp[temp.findIndex((foo) => foo.id == parentId)] = {
        ...parent,
        selected: parentNewSelectedValue,
      };
      return [...temp];
    });

    return correctParentState(parent, parentNewSelectedValue);
  }
  function handleSelect(item, value) {
    selectItemAndChildren(item, value);
    correctParentState(item, value);
    onDirty(true);
  }

  return (
    <Box sx={{ overflow: "auto", ...sx }}>
      <SimpleTreeView {...rest}>
        {flatListToNested(
          _.uniqBy(list, "id").filter((item) => !!item),
          (item) => item?.id,
          (item) => item?.parentId
        ).map((item) => {
          return (
            <Branch item={item} onSelect={handleSelect} disabled={disabled} />
          );
        })}
      </SimpleTreeView>
    </Box>
  );
}
