import { useState } from "react";
import { Box, Button } from "@mui/material";
import AttachmentList from "../../../../../blocks/AttachmentUploadModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AttachmentUploader from "../../../../../blocks/AttachmentUploadModal/Uploader";
function ImageInput({ value, isProfessor, onChange }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleClick = () => {
    setIsListModalOpen(true);
  };

  const onFinishUpload = (attachment) => {
    const newValue = [...value];
    const valueIndex = value.findIndex(
      ({ resourceId }) => resourceId === attachment.resourceId
    );
    if (valueIndex === -1) {
      attachment.isValid = false;
      attachment.condition = "added";
      newValue.push(attachment);
    } else {
      newValue[valueIndex].condition = "default";
    }
    onChange([...newValue]);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginY: "10px",
      }}
    >
      <Button onClick={() => handleClick()} variant="outlined">
        Selecionar imagem
      </Button>
      <AttachmentList
        open={isListModalOpen}
        onClose={() => setIsListModalOpen(false)}
        value={value || []}
        onChange={onChange}
        imagesOnly
      />
      <AttachmentUploader
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onFinishUpload={(newAttachment) => {
          onFinishUpload(newAttachment);
        }}
      />
    </Box>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  status: state.attachment.status,
  isProfessor: state.auth.user.profile.isProfessor,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageInput);
