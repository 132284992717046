import { useChapter } from "../api/getChapterData";
import { ChatPlus } from "mdi-material-ui";
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

import RenderContents from "../../../ui/components/RenderContents";
import { useTheme } from "@emotion/react";
const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey["200"],
  height: "calc(100dvh - 64px)",
  width: "100%",
  overflow: "auto",
}));

export default function DirectAccessChapter({ course, ...props }) {
  const params = useParams();
  const chapterQuery = useChapter({ chapterId: params.capituloid });
  const theme = useTheme();

  if (chapterQuery.isLoading || chapterQuery.isError || !chapterQuery.data) {
    return (
      <Wrapper sx={{ display: "grid" }}>
        <CircularProgress sx={{ margin: "auto", alignSelf: "center" }} />
      </Wrapper>
    );
  }
  switch (chapterQuery.data.TIPO) {
    case "CONTEUDO":
      return (
        <Wrapper
          sx={{
            "& > div": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              overflow: { xs: "visible", md: "auto" },
            },
          }}
        >
          <Typography
            sx={{
              p: 1,
              ml: "124px",
            }}
            color="text.secondary"
          >
            {chapterQuery.data.NOME}
          </Typography>
          <RenderContents currentChapter={params.capituloid} course={course} />
        </Wrapper>
      );
    default:
      return (
        <Wrapper sx={{ display: "grid" }}>
          <Paper sx={{ p: 4, margin: "auto", alignSelf: "center" }}>
            <Typography variant="h6">
              Para ver esse conteúdo, é necessário acessá-lo através de um
              curso.
            </Typography>
          </Paper>
        </Wrapper>
      );
  }
}
