import { useCallback, useEffect, useState } from "react";

import { TargetBox } from "./TargetBox";
import {
  Box,
  Button,
  FormHelperText,
  LinearProgress,
  Typography,
} from "@mui/material";
import { CloudUpload, List } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { FilesList } from "./FilesList";
function ControlledFileInput({
  multiple = false,
  loading = false,
  control,
  helperText = "",
  accept = "",
  error = false,
  name,
}) {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });
  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = Array.from(item.files);
        // console.log({ files });

        if (multiple) {
          append(
            files.map((file) => ({
              // fileBlob: new Blob([file], { type: file?.type }),
              file,
              type: file.type,
              name: file.name,
              size: file.size,
            }))
          );
        } else {
          replace([
            {
              file: files[0],
              type: files[0].type,
              name: files[0].name,
              size: files[0].size,
            },
          ]);
        }
      }
    },
    [fields]
  );

  const handleDeleteFile = useCallback(
    (deletedItemIndex) => {
      remove(deletedItemIndex);
    },
    [fields]
  );
  return (
    <Box
      sx={{
        height: "250px",
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="h6"> Aguarde um momento...</Typography>
          <LinearProgress sx={{ width: "160px" }} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              aspectRatio: "16 / 9",
              width: "100%",
              height: "auto",
            }}
          >
            <TargetBox
              accept={accept}
              renderList={(fileInputRef) => {
                return (
                  <FilesList
                    fileInputRef={fileInputRef}
                    files={fields.map((field) => field.file)}
                    onDelete={handleDeleteFile}
                  />
                );
              }}
              files={fields.map((field) => field.file)}
              multiple={multiple}
              onDrop={handleFileDrop}
              onDelete={handleDeleteFile}
              error={error}
            />
            <FormHelperText error={error}>{helperText}</FormHelperText>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ControlledFileInput;
