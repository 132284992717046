import { Box, Checkbox, FormControlLabel } from "@mui/material";
import VideoContent from "./VideoContent";
import VideoInput from "./VideoInput";

function VideoComponent({
	contentId,
	mandatory,
	sx,
	value,
	onChange,
	hideControls,
	setHideControls,
	isEditing = false,
	...props
}) {

	return (
		<Box sx={{ width: "100%", height: "100%", margin: "0" }}>
			{isEditing && (
				<>
					<VideoInput sx={sx} onChange={onChange} videoUrl={value} />
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={hideControls === "S"}
									onChange={(event) =>
										setHideControls(event.target.checked ? "S" : "N")
									}
								/>
							}
							label='Ocultar controles do player'
						/>
					</Box>
				</>
			)}
			<VideoContent
				contentId={contentId}
				mandatory={mandatory}
				sx={sx}
				url={value}
				hideControls={hideControls === "S" || props?.options?.removePlayerControl === "S"}
				isEditing={isEditing}
				{...props}
			/>
		</Box>
	);
}

export default VideoComponent;
