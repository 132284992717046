import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  finishAttempt,
  FINISH_ATTEMPT,
  getFullEvaluation,
  getResults,
  GET_FULL_EVALUATION,
  GET_RESULTS,
  startAttempt,
  START_ATTEMPT,
} from "../../../../../../../../shared/store/evaluation";
import MuiContainer from "@mui/material/Container";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Clear, DoneOutline } from "@mui/icons-material";
import Loading from "../../../../../../../components/Loading";
import Linear from "./Linear";
import Quiz from "./Quiz";
import Alert from "../../../../../../../components/Alert";
import { useCallbackPrompt } from "../../../../../../../../shared/hooks/useCallbackPrompt";

const Container = styled(MuiContainer, { disableGutters: true })(() => ({
  fontFamily: "Poppins",
  color: "#6E6D7A",
  borderRadius: "5px",
  backgroundColor: "white",
  padding: "16px",
  "@media screen and (min-width: 1200px)": {
    maxWidth: "unset",
  },
}));

function EvaluationContent({
  isEnquete,
  evaluationId,
  token,
  studentId,
  enrollmentId,
  attempt,
  requestsStatus,
  currentEvaluation,
  results,
  getFullEvaluation,
  startAttempt,
  finishAttempt,
  currentAttempt,
  getResults,
}) {
  const [inEvaluation, setInEvaluation] = useState(false);
  const [attemptAnswers, setAttemptAnswers] = useState(null);
  const [openResults, setOpenResults] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(false);
  const attemptId = attempt?.attemptId || null;

  const approved =
    results?.result === "APROVADO" ||
    (results?.approved !== undefined && results?.approved === true);
  const disapproved =
    // currentEvaluation?.type === "CAP" &&
    results?.result === "REPROVADO" ||
    (results?.approved !== undefined && results?.approved === false);
  const totalAttempts =
    results?.numberOfRetryes !== undefined
      ? results.numberOfRetryes
      : results?.numberOfAttempts !== undefined
      ? results?.numberOfAttempts
      : null;

  function onStartEvaluation() {
    startAttempt(evaluationId, studentId, enrollmentId, token);
  }

  function getStringAnswer(answer, question) {
    if (
      question.category === "discursive" ||
      question.category === "autoCompleteList" ||
      (question.type === "quantity" && question.listOptions.length === 1)
    ) {
      return String(answer);
    }

    return (
      question.listOptions?.[Number(answer ?? "null")]?.optionDescription ?? ""
    );
  }

  function onSubmit(answers) {
    const answersArray = answers.map((value, index) => ({
      questionId: currentEvaluation.questions[index].questionId,
      answer: getStringAnswer(value, currentEvaluation.questions[index]),
    }));

    const notAnsweredQuestions = currentEvaluation.questions?.reduce(
      (currentArray, _, index) => {
        if (!answers?.[index]) {
          return [...currentArray, index + 1];
        }

        return currentArray;
      },
      []
    );

    if (notAnsweredQuestions?.length > 0 && currentEvaluation.type !== "CAP") {
      alert(
        `Você ainda não respondeu a todas as perguntas.\nResponda à(s) questão(ões):\n${notAnsweredQuestions?.join(
          "\n"
        )}`
      );

      return;
    }

    setAttemptAnswers(answersArray);

    finishAttempt(attemptId, answersArray);
  }

  useEffect(() => {
    if (attemptId) {
      setInEvaluation(true);
    }
  }, [attemptId]);

  useEffect(() => {
    getFullEvaluation(evaluationId);
    getResults(evaluationId, enrollmentId, token);
  }, [evaluationId, enrollmentId, token]);

  useEffect(() => {
    setInEvaluation(false);

    if (
      !!results &&
      !!attemptAnswers &&
      currentEvaluation?.showResults === "S"
    ) {
      setOpenResults(true);
    }
  }, [results]);

  const isQuiz = currentEvaluation?.format === "QUIZ";

  const EvaluationComponent = isQuiz ? Quiz : Linear;

  const theme = useTheme();
  return (
    <>
      {inEvaluation ? (
        <Container
          sx={{
            marginY: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "3rem",
              paddingBottom: "3rem",
            },
          }}
        >
          <EvaluationComponent
            evaluation={currentEvaluation}
            onSubmit={onSubmit}
            timeEnded={currentAttempt?.time?.ended}
            timeRunning={currentAttempt?.time?.running}
          />
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ padding: "0.5rem" }}>
              <Box sx={{ display: "flex", gap: "0.25rem" }}>
                {!disapproved && !isEnquete && (
                  <>
                    <DoneOutline
                      sx={{
                        color: "green",
                      }}
                    />
                    <Typography>
                      Parabéns, você foi <b>APROVADO</b>!
                    </Typography>
                  </>
                )}

                {disapproved &&
                  !isEnquete &&
                  currentEvaluation?.maximumAttempts === totalAttempts &&
                  currentEvaluation?.maximumAttempts !== 0 && (
                    <>
                      <Clear
                        sx={{
                          color: "red",
                        }}
                      />
                      <Typography>
                        Você foi <b>REPROVADO</b>!
                      </Typography>
                    </>
                  )}

                {isEnquete && totalAttempts > 0 && approved && (
                  <>
                    <Typography variant="h4">
                      Respostas enviadas com sucesso!
                    </Typography>
                  </>
                )}
              </Box>
              {!isEnquete && (
                <List>
                  {results?.totalRightAnswer !== undefined ? (
                    <ListItem>
                      {"Questões corretas:"}
                      <b style={{ marginLeft: "0.25rem" }}>
                        {results?.totalRightAnswer} de{" "}
                        {currentEvaluation?.questions?.length}
                      </b>
                    </ListItem>
                  ) : (
                    <ListItem>
                      {"Número total de questões:"}
                      <b style={{ marginLeft: "0.25rem" }}>
                        {currentEvaluation?.questions?.length ?? 0}
                      </b>
                    </ListItem>
                  )}

                  <ListItem>
                    {"Número mínimo de acertos:"}
                    <b style={{ marginLeft: "0.25rem" }}>
                      {currentEvaluation?.toBeApproved ?? 0}
                    </b>
                  </ListItem>
                  {totalAttempts !== null &&
                  currentEvaluation?.maximumAttempts !== 0 ? (
                    <ListItem>
                      {"Número de tentativas realizadas:"}
                      <b style={{ marginLeft: "0.25rem" }}>
                        {totalAttempts} de {currentEvaluation?.maximumAttempts}
                      </b>
                    </ListItem>
                  ) : (
                    <ListItem>
                      {"Número máximo de tentativas:"}
                      <b style={{ marginLeft: "0.25rem" }}>
                        {!!currentEvaluation?.maximumAttempts
                          ? currentEvaluation?.maximumAttempts
                          : "ilimitado"}
                      </b>
                    </ListItem>
                  )}
                  <ListItem>
                    Tempo para conclusão:
                    <b style={{ marginLeft: "0.25rem" }}>
                      {currentEvaluation?.time === 0
                        ? "ilimitado"
                        : `${currentEvaluation?.time} minuto${
                            currentEvaluation?.time === 1 ? "" : "s"
                          }`}
                    </b>
                  </ListItem>
                </List>
              )}
            </Box>
            {(currentEvaluation?.maximumAttempts > totalAttempts &&
              disapproved) ||
            (currentEvaluation?.maximumAttempts === 0 && disapproved) ||
            (isEnquete && disapproved) ? (
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{
                    margin: "auto",
                    backgroundColor: "var(--primary_color_main)",
                  }}
                  variant="contained"
                  onClick={onStartEvaluation}
                >
                  <Typography color="white">
                    Iniciar {isEnquete ? "Enquete" : "Avaliação"}
                  </Typography>
                </Button>
              </Box>
            ) : isEnquete ? (
              <></>
            ) : (
              <Typography sx={{ width: "100%", textAlign: "center" }}>
                Você não pode realizar mais tentativas!
              </Typography>
            )}
          </Box>
        </Container>
      )}

      <Dialog open={openResults}>
        <DialogTitle>Revisão de resposta:</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {currentEvaluation?.questions?.map((question, index, questions) => (
              <Box
                key={`question-${question?.questionId}`}
                sx={{ minWidth: "30rem" }}
              >
                <Typography marginY="0.25rem">
                  <b>Questão: </b>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.statement }}
                  />
                </Typography>
                {question?.category !== "discursive" && (
                  <Typography marginY="0.25rem">
                    <b>Resposta certa: </b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          question?.listOptions?.[
                            Number(question?.answerTemplate)
                          ]?.optionDescription,
                      }}
                    />
                  </Typography>
                )}
                <Typography marginY="0.25rem">
                  <b>Sua resposta: </b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: attemptAnswers?.[index]?.answer,
                    }}
                  />
                </Typography>
                {index !== questions?.length - 1 && <Divider />}
              </Box>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setOpenResults(false);
              setAttemptAnswers(null);
            }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          requestsStatus[GET_FULL_EVALUATION] === "loading" ||
          requestsStatus[GET_RESULTS] === "loading" ||
          requestsStatus[START_ATTEMPT] === "loading" ||
          requestsStatus[FINISH_ATTEMPT] === "loading"
        }
      >
        <Box sx={{ position: "relative", width: "5rem", height: "5rem" }}>
          <Loading />
        </Box>
      </Dialog>

      <Alert
        closeAlert={() => {
          cancelNavigation();
        }}
        showAlert={showPrompt}
        text={`Se você sair dessa página sem enviar respostas, isso contará como uma tentativa`}
        title={"Alerta"}
        footerActions={
          <Button
            onClick={() => {
              confirmNavigation();
            }}
          >
            Sair mesmo assim
          </Button>
        }
        closeButtonText={"Ficar nessa página"}
      ></Alert>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  studentId: state.course?.enrollment?.ALUNOID,
  enrollmentId: state.course?.enrollment?.MATRICULAID,
  attempt: state.evaluation.attemptInfo,
  requestsStatus: state.evaluation.status,
  currentEvaluation: state.evaluation.currentEvaluation,
  currentAttempt: state?.evaluation?.attemptInfo,
  results: state.evaluation.results,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFullEvaluation,
      startAttempt,
      finishAttempt,
      getResults,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationContent);
