import { Box, Input } from "@mui/material";
import { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";

export default function TagsInput({ tags, setTags }) {
  const [inputValue, setInputValue] = useState("");

  function addTag() {
    const newTag = inputValue;

    setTags((prev) => [...prev, newTag]);
    setInputValue("");
  }

  function removeTag(index) {
    setTags((prev) => {
      const newTags = structuredClone(prev);

      newTags.splice(index, 1);

      return newTags;
    });
  }

  const TagCard = ({ name, index }) => (
    <Box
      sx={{
        backgroundColor: "#FF6600",
        color: "white",
        width: "fit-content",
        paddingLeft: "6px",
        borderRadius: "5px",
        display: "flex",
        gap: "6px",
      }}
    >
      {name}
      <ClearIcon
        sx={{ cursor: "pointer", fontSize: "18px" }}
        onClick={() => removeTag(index)}
      />
    </Box>
  );

  return (
    <Box>
      {tags?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            flexWrap: "wrap",
            paddingY: "6px",
          }}
        >
          {tags.map((tag, index) => (
            <TagCard key={`tag-${index}`} name={tag} index={index} />
          ))}
        </Box>
      )}
      <Input
        id={`attachment-tag-input`}
        value={inputValue}
        style={{ width: "100%" }}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Tab") {
            event.preventDefault();
          }

          if (
            (event.key === "Enter" || event.key === "Tab") &&
            inputValue?.length > 0
          ) {
            addTag();
          }
        }}
      />
    </Box>
  );
}
