import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { useTenantParameters } from "./getTenantParameters";
import _cookies from "../../../shared/utils/_cookies";

export const getVimeoVideos = async (params = {}) => {
  const res = await axios.cadernoVirtualBase("/server/api/vimeo/list", {
    params,
  });

  return res;
};

export const useVimeoVideos = ({ config, params } = {}) => {
  const tenantParams = useTenantParameters();
  const { myVideos, page, itensPerPage } = params;

  return useQuery({
    ...config,
    enabled: tenantParams.isSuccess,
    queryKey: ["vimeoVideos", params],
    queryFn: () =>
      getVimeoVideos({
        ...params,
        album_id: tenantParams?.data?.VIMEO_ALBUMID,
        page,
        user:
          _cookies.getItem("auth").user?.profile?.isProfessor?.ID ||
          encodeURIComponent(_cookies.getItem("auth").user.nome),
        per_page: itensPerPage,
        my_videos: myVideos,
      }),
  });
};
