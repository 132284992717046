import { DeleteOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { baseUrl } from "../../../../../../../shared/config";
import _cookies from "../../../../../../../shared/utils/_cookies";
import AttachmentIcon from "./Icon";
import { useParams } from "react-router-dom";
import LabelledSwitch from "../../../../../../components/LabelledSwitch";
import PreviewMarker from "../../../partials/PreviewMarker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { markView } from "../../../../common/redux/CourseActionCreators";

function Card({
  contentId,
  attachment,
  index,
  isEditing,
  removeAttachment,
  mandatory,
  setMandatory,
  auth,
  enrollment,
  chapters,
  markView,
  canBeMandatory = false,
}) {
  const { currentChapter } = useParams();
  const downloadRef = useRef();

  const link = attachment.LINK ?? attachment.link;
  const resourceId = attachment.RECURSOID ?? attachment.resourceId;
  const name = attachment.NOME ?? attachment.name;

  const lastLinkWord = link.split(".").pop();
  const attachmentExtension = lastLinkWord.length <= 4 ? lastLinkWord : "URL";

  const [file, setFile] = useState(null);

  const anexosVisualizados =
    chapters?.find((chapter) => chapter?.capituloid === Number(currentChapter))
      ?.views || [];

  const isAttachmentViewed = () =>
    anexosVisualizados?.find(
      (attachmentViewed) => attachmentViewed.RECURSOID === resourceId
    );

  async function getFile() {
    const response = await fetch(`${baseUrl}/Membros/RECURSOS/${link}`, {
      method: "GET",
    });

    const blob = await response.blob();

    const url = URL.createObjectURL(blob);

    if (
      !!url &&
      !!auth?.user?.alunoid &&
      !!auth?.token &&
      !!enrollment?.MATRICULAID &&
      !!currentChapter &&
      !!contentId &&
      !!resourceId
    ) {
      markView(
        auth.user.alunoid,
        auth.token,
        enrollment.MATRICULAID,
        currentChapter,
        contentId,
        resourceId
      );
    }

    setFile(url);
  }

  const slicedName = [name.slice(0, -7), name.slice(-7)];

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "5px",
        border: "1px solid var(--primary_color_main)",
      }}
    >
      <AttachmentIcon extension={attachmentExtension} />
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          justifyContent: "space-between",
          gap: "0.5rem",
          borderRadius: "5px",
          backgroundColor: "var(--primary_color_main)",
          color: "white",
          alignItems: "center",
          paddingX: "12px",
          maxWidth: "calc(100% - 45px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            color: "white",
            justifyContent: "start",
            alignItems: "center",
            ":hover": {
              textDecoration: "underline",
            },
            overflow: "hidden",
          }}
          onClick={async () => {
            await getFile();
            downloadRef.current.click();
          }}
          title={name}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {slicedName[0]}
          </Typography>
          <Typography>{slicedName[1]}</Typography>
        </Box>
        {!isEditing && mandatory === "S" && (
          <PreviewMarker viewed={isAttachmentViewed()} />
        )}
        {isEditing && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,.9)",
                borderRadius: "0.5rem",
              }}
            >
              {canBeMandatory && (
                <LabelledSwitch
                  label={"Obrigatório?"}
                  color="#6186F8"
                  labelPlacement="start"
                  fontWeight="700"
                  textTransform="uppercase"
                  sx={{
                    marginRight: "0",
                    marginLeft: "0.5rem",
                  }}
                  value={mandatory === "S"}
                  onChange={(event) =>
                    setMandatory(event.target.checked ? "S" : "N")
                  }
                />
              )}
            </Box>
            <DeleteOutlined
              sx={{ fontSize: "25px", cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                removeAttachment(index);
              }}
            />
          </Box>
        )}
      </Box>
      <a ref={downloadRef} href={`${link}`} download={link} hidden />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ markView }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Card);
