import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Question as NewQuestionComponent } from "../../../features/questions/components/Question";
import { surveyQuestionTemplates } from "../../../features/survey/utils/surveyQuestionTemplates";
import Question from "../Course/pages/EditCourse/Evaluation/Question";
import { questionTypes } from "../Course/pages/EditCourse/Evaluation/questionCategories";
import RichText from "../../components/RichText";
import { useSurvey } from "../../../features/survey/api/getSurvey";
import { useStartSurveyAttempt } from "../../../features/survey/api/startSurveyAttempt";
import { useFinishSurveyAttempt } from "../../../features/survey/api/finishSurveyAttempt";
import _cookies from "../../../shared/utils/_cookies";
import { LoadingButton } from "@mui/lab";
import { createNotification } from "../../../shared/store/notifications";
import moment from "moment";
import DatesLabel from "../../components/DatesLabel";

export default function Survey({
  fallbackStudentId = null,
  surveyId = null,
  onFinishAttemptSuccess = () => {},
}) {
  const params = useParams();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState([]);
  const [redirectToLoginDialogOpen, setRedirectToLoginDialogOpen] =
    useState(false);
  const [notAnsweredMandatoryQuestions, setNotAnsweredMandatoryQuestions] =
    useState([]);

  const researchId = params?.id || surveyId;

  const survey = useSurvey({ surveyId: researchId });
  const startAttempt = useStartSurveyAttempt({});
  const finishAttempt = useFinishSurveyAttempt({});

  const now = moment(new Date());
  const isLate = moment(survey.data?.endDate, "DD-MM-YYYY hh:mm:ss").isBefore(
    now
  );
  const isEarly = now.isBefore(
    moment(survey.data?.startDate, "DD-MM-YYYY hh:mm:ss")
  );

  const allowedToStartAttempt = !isLate && !isEarly;

  function getStringAnswer(answer, question) {
    if (question.newQuestionType === "upload") {
      return answer?.join(",");
    }
    if (
      question.category === "discursive" ||
      question.category === "autoCompleteList" ||
      (question.type === "quantity" && question.listOptions.length === 1)
    ) {
      return String(answer);
    }

    return (
      question.listOptions?.[Number(answer ?? "null")]?.optionDescription ?? ""
    );
  }

  const onSubmit = async () => {
    const answersArray = answers.map((value, index) => ({
      questionId: survey.data.questions[index].questionId,
      answer: getStringAnswer(value, survey.data.questions[index]),
    }));

    const notAnsweredQuestions = survey.data.questions?.reduce(
      (currentArray, _, index) => {
        if (
          answers?.[index] === undefined &&
          survey.data.questions[index].mandatory == "S"
        ) {
          return [...currentArray, index + 1];
        }

        return currentArray;
      },
      []
    );
    setNotAnsweredMandatoryQuestions(notAnsweredQuestions);

    if (notAnsweredQuestions?.length > 0) {
      alert(
        `Você ainda não respondeu a todas as perguntas.\nResponda à(s) questão(ões):\n${notAnsweredQuestions?.join(
          "\n"
        )}`
      );

      return;
    }

    await finishAttempt.mutate(
      {
        attempts: answersArray,
        researchAttemptsId: startAttempt.data.id,
      },
      {
        onSuccess: () => {
          createNotification({
            type: "success",
            message: "Respostas registradas com sucesso",
          });
          onFinishAttemptSuccess();
        },
      }
    );
  };

  const handleLoginRedirect = () => {
    navigate(`/login?redirectTo=pesquisa/${researchId}`);
  };

  const handleStartAttempt = () => {
    if (
      !(_cookies.getItem("auth")?.user?.alunoid || fallbackStudentId) &&
      survey?.data?.mandatory !== "P"
    ) {
      setRedirectToLoginDialogOpen(true);
    } else {
      startAttempt.mutate({
        researchId,
        studentId: _cookies.getItem("auth")?.user?.alunoid || fallbackStudentId,
      });
    }
  };

  const theme = useTheme();

  return (
    <>
      <Dialog open={redirectToLoginDialogOpen}>
        <DialogTitle>Pesquisa privada</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para responder essa pesquisa você precisa estar logado. <br />
            Deseja ser redirecionado para página de login?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRedirectToLoginDialogOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleLoginRedirect}>
            Redirecionar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          padding: 1,
          overflow: "auto",
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            paddingBottom: "3rem",
          },
        }}
        maxWidth="unset"
      >
        <Box
          sx={{
            marginY: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginX: "auto",
          }}
          maxWidth="1200px"
        >
          {survey?.isLoading ? (
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          ) : (
            <>
              <Typography variant="h6">{survey.data?.title}</Typography>
              <DatesLabel
                orientation="row"
                datesArray={[
                  {
                    label: "Data de início",
                    date: moment(
                      survey.data?.startDate,
                      "DD-MM-YYYY hh:mm:ss"
                    ).format("DD/MM/YYYY HH:mm:ss"),
                  },
                  {
                    label: "Data final",
                    date: moment(
                      survey.data?.endDate,
                      "DD-MM-YYYY hh:mm:ss"
                    ).format("DD/MM/YYYY HH:mm:ss"),
                  },
                ]}
              />
              <RichText value={survey.data?.orientations} />
            </>
          )}
          <Divider />
          {startAttempt.isSuccess ? (
            <>
              {survey.data?.questions?.length > 0 &&
                survey.data?.questions.map((question, index) => {
                  if (question.newQuestionType == "upload") {
                    return (
                      <NewQuestionComponent
                        error={
                          question.mandatory &&
                          notAnsweredMandatoryQuestions.some(
                            (position) => position == index + 1
                          )
                        }
                        label={`Questão ${index + 1} de ${
                          survey.data?.questions?.length
                        }`}
                        key={`question-${index}`}
                        submittedAnswer={answers?.[index]}
                        initialQuestionData={question}
                        questionTemplates={surveyQuestionTemplates}
                        setSubmittedAnswer={(newValue) => {
                          setAnswers((prev) => {
                            const newAnswers = [...prev];
                            newAnswers[index] = newValue;
                            return newAnswers;
                          });
                        }}
                        attemptId={startAttempt.data.id}
                      />
                    );
                  }
                  return (
                    <Question
                      error={
                        question.mandatory &&
                        notAnsweredMandatoryQuestions.some(
                          (position) => position == index + 1
                        )
                      }
                      key={`question-${index}`}
                      value={answers?.[index]}
                      onChange={(event) => {
                        const newValue = event?.target?.value ?? event;

                        setAnswers((prev) => {
                          const newAnswers = [...prev];
                          newAnswers[index] = newValue;
                          return newAnswers;
                        });
                      }}
                      question={question}
                      index={index}
                      evaluation={survey.data}
                      questionTypes={questionTypes}
                      exhibition
                    />
                  );
                })}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  gap: "2rem",
                }}
              >
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={
                    survey.data?.mandatory === "L" &&
                    survey.data?.numberOfAttempts > 0
                  }
                  loading={finishAttempt.isLoading}
                >
                  {survey.data?.mandatory === "L" &&
                  survey.data?.numberOfAttempts > 0
                    ? "Respondido"
                    : "Enviar Respostas"}
                </LoadingButton>
              </Box>
            </>
          ) : (
            <Box>
              {startAttempt.isLoading || startAttempt.isError ? (
                <CircularProgress
                  sx={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              ) : (
                <>
                  <Button
                    sx={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    variant="outlined"
                    disabled={
                      survey.isLoading ||
                      survey.isError ||
                      !allowedToStartAttempt
                    }
                    onClick={handleStartAttempt}
                  >
                    Responder
                  </Button>
                  {(isLate || isEarly) && (
                    <Typography
                      sx={{
                        textAlign: "center",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      color="error"
                    >
                      <br />
                      {isEarly && "Esta pesquisa ainda não começou!"}
                      {isLate && "Esta pesquisa já foi encerrada!"}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
