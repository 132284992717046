import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import AttachmentList from "../../../../../blocks/AttachmentUploadModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function DocInput({ onChange, initialValue, isProfessor }) {
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [attachments, setAttachments] = useState([{ link: initialValue }]);
  const handleClick = () => {
    setIsListModalOpen(true);
  };
  const handleChange = (newAttachments) => {
    if (newAttachments?.length > 0) {
      if (!!attachments[0] && !!attachments[0]?.resourceId) {
        const addedAttachment = newAttachments?.find(
          (attachment) =>
            !!attachment?.resourceId &&
            attachment.resourceId !== attachments[0]?.resourceId
        );
        setAttachments([addedAttachment]);
      } else {
        setAttachments(newAttachments);
      }
    } else {
      setAttachments([]);
    }
  };

  const onFinishUpload = (attachment) => {
    const newValue = [...attachments];
    const valueIndex = attachments.findIndex(
      ({ resourceId }) => resourceId === attachment.resourceId
    );
    if (valueIndex === -1) {
      attachment.isValid = false;
      attachment.condition = "added";
      newValue.push(attachment);
    } else {
      newValue[valueIndex].condition = "default";
    }
    setAttachments([...newValue]);
  };
  useEffect(() => {
    if (attachments.length > 0 && attachments[attachments?.length - 1]?.link) {
      onChange(attachments[attachments.length - 1]?.link);
    } else {
      onChange("");
    }
  }, [attachments]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginY: "10px",
      }}
    >
      <Button onClick={() => handleClick()} variant="outlined">
        Selecionar documento
      </Button>
      <AttachmentList
        open={isListModalOpen}
        onClose={() => setIsListModalOpen(false)}
        value={attachments}
        onChange={handleChange}
        documentsOnly
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  status: state.attachment.status,
  isProfessor: state.auth.user.profile.isProfessor,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocInput);
