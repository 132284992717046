import { useState } from "react";
import React from "react";
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogContentText,
  useTheme,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import { useUploadVimeoVideo } from "../api/uploadVimeoVideo";
import Form from "../../../ui/components/Form";
import * as yup from "yup";
import ControlledVideoInput from "../../../ui/components/DragAndDropFileInput/ControlledVideoInput";
import { useTenantParameters } from "../api/getTenantParameters";
import { Close } from "@mui/icons-material";

// case "MP4":
//   case "MOV":
//   case "WMV":
//   case "AVI":
//   case "FLV":

const acceptedVideoTypes = [
  "video/mp4",
  "video/mov",
  "video/wmv",
  "video/avi",
  "video/flv",
];

const fileSchema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf(
      acceptedVideoTypes,
      "Extensões de arquivos permitidas: mp4,mov,wmv,avi,flv"
    ),
  title: yup.string().required("Campo obrigatório"),
});

const schema = yup.object().shape({
  // title: yup.string().required("Campo obrigatório"),
  files: yup.array().of(fileSchema).min(1, "Escolha um arquivo"),
  // .max(1, "Escolha apenas um arquivo"),
});

const defaultValues = {
  title: "",
  files: [],
};

export default function VimeoVideoUploader() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progress, setProgress] = useState([]);

  const theme = useTheme();
  const isDisplaySmall = useMediaQuery(theme.breakpoints.down("md"));
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const tenantParameters = useTenantParameters();
  const { isLoading, isSuccess, isSettled, mutate } = useUploadVimeoVideo({
    onProgress: () => {},
    config: {
      onSettled: () => {
        handleCloseDialog();
      },
    },
  });

  const handleProgress = (value, index) => {
    console.log("handleProgress");
    setProgress((prev) => {
      const newArr = [...prev];
      newArr[index] = value;
      return newArr;
    });
    return;
  };

  const handleSubmit = (values) => {
    values.files.forEach((file, index) => {
      mutate({
        title: file.title,
        file: file.file,
        albumId: tenantParameters?.data?.VIMEO_ALBUMID,
        onProgress: (value) => handleProgress(value, index),
      });
    });
  };

  if (tenantParameters.isLoading) {
    <CircularProgress />;
  }
  return (
    <>
      <Button onClick={handleOpenDialog}>Adicionar novo</Button>
      <Dialog
        fullScreen={isDisplaySmall}
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>Subir vídeo</DialogTitle>
        <IconButton
          sx={{ position: "absolute", top: 3, right: 3 }}
          onClick={handleCloseDialog}
        >
          <Close />
        </IconButton>
        <DialogContent
          sx={{
            "& > form": { display: "flex", flexDirection: "column", gap: 1 },
            "& > form > *:first-child": {
              // backgroundColor: "red",
              // display: "block",
              // height: "450px",
            },
            "& > form > *": { width: "100%" },
          }}
        >
          {isSettled ? (
            <>
              <DialogContentText>
                Seu vídeo está sendo processado e estará disponível em algun
                minutos
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Ok</Button>
              </DialogActions>
            </>
          ) : (
            <Form
              defaultValues={defaultValues}
              schema={schema}
              onSubmit={handleSubmit}
            >
              {({ register, control, formState }) => {
                return (
                  <>
                    <Box sx={{ height: "450px" }}>
                      <ControlledVideoInput
                        accept={acceptedVideoTypes.join(",")}
                        name="files"
                        control={control}
                        register={register}
                        helperText={
                          formState.errors["files"]?.message ||
                          formState.errors["files"]?.[0].type?.message
                        }
                        error={!!formState.errors["files"]}
                        multiple={true}
                        progress={progress}
                      />
                    </Box>

                    <Button type="submit" disabled={isLoading}>
                      Subir
                    </Button>
                  </>
                );
              }}
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
