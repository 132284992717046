import {
  List,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
  ListItem,
  Container,
  SvgIcon,
  ListItemAvatar,
  ListItemText,
  Paper,
  Avatar,
  Grid,
} from "@mui/material";
import { CloseCircleOutline } from "mdi-material-ui";
import { useState } from "react";
import { bindActionCreators } from "redux";
import AttachmentUploader from "./Uploader";
import {
  listAll,
  setAttachment,
  removeAttachment,
} from "../../../shared/store/attachment";
import { connect } from "react-redux";
import { useEffect } from "react";
import CardName from "../Carousel/Card/Name";
import CardActions from "../Carousel/Card/Actions";
import { baseUrl } from "../../../shared/config";
import { InsertDriveFileOutlined, Link } from "@mui/icons-material";
import SearchBar from "./SearchBar";
import { UploadAttachment } from "../../../features/misc/components/UploadAttachment";
import { useAttachments } from "../../../features/misc/api/getAttachments";
import { useDeleteAttachment } from "../../../features/misc/api/deleteAttachment";

const acceptedImageTypes = [""];
const acceptedDocTypes = [""];

function AttachmentList({
  open,
  onClose,
  value,
  onChange,
  auth,
  listAll,
  removeAttachment,
  imagesOnly = false,
  documentsOnly = false,
}) {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const deleteAttachment = useDeleteAttachment();

  const theme = useTheme();

  const [attachmentsList, setAttachmentsList] = useState([]);

  const type = imagesOnly ? "IMAGES" : documentsOnly ? "DOC" : null;
  const { data } = useAttachments({
    type,
    config: {
      onSuccess: (data) => {
        setAttachmentsList(data);
      },
    },
  });

  const filterList = (filterTerm) => {
    const regex = new RegExp("(" + filterTerm + ")", "i");
    setAttachmentsList(
      data.map((item) => {
        if (regex.test(item?.name)) {
          return item;
        }
      })
    );
  };

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];

  const attachmentExtension = (attachment) => {
    const lastLinkWord = attachment?.link?.split(".")?.pop() || "URL";

    return lastLinkWord.length <= 4 ? lastLinkWord : "URL";
  };

  const isImage = (attachment) =>
    imageExtensions.some((type) => type === attachmentExtension(attachment));

  const isSelected = (attachment) =>
    value?.some(
      ({ resourceId, condition }) =>
        resourceId === attachment.resourceId && condition !== "removed"
    );

  const handleToggle = (attachment, added, index) => {
    const newValue = [...value];

    const valueIndex = value.findIndex(
      ({ resourceId }) => resourceId === attachment.resourceId
    );

    if (added) {
      if (valueIndex === -1) {
        attachment.isValid = false;
        attachment.condition = "added";
        newValue.push(attachment);
      } else {
        newValue[valueIndex].condition = "default";
      }
    } else {
      if (newValue[valueIndex].isValid) {
        newValue[valueIndex].condition = "removed";
      } else {
        newValue.splice(valueIndex, 1);
      }
    }

    onChange([...newValue]);
  };

  const breakString = theme.breakpoints.up("md");
  const isDisplayMedium = useMediaQuery(breakString);

  return (
    <Dialog
      maxWidth={"xl"}
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <UploadAttachment
          accept={
            imagesOnly
              ? ".jpg,.jpeg,.png,.svg,.gif,.bmp"
              : documentsOnly
              ? ".pdf,.pptx,.xls,.doc,.docx"
              : ""
          }
          onSuccess={() => {
            listAll(
              auth.user.username,
              auth.token,
              imagesOnly ? "IMAGES" : documentsOnly ? "DOC" : null
            );
          }}
        />
        <SearchBar onClickSearch={filterList}></SearchBar>
        <IconButton onClick={onClose}>
          <CloseCircleOutline />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: "5px",
          paddingBottom: "5px",
          minHeight: "20rem",
          height: "100vh",
          maxHeight: isDisplayMedium ? "60vh" : "400px",
        }}
      >
        {attachmentsList?.length > 0 && (
          <Grid container spacing={2}>
            {attachmentsList.map((attachment, index) => (
              <>
                {attachment && (
                  <Grid item xs={12} md={6}>
                    <ListItem
                      sx={{
                        borderRadius: "12px",
                        backgroundColor: theme.palette.grey["300"],
                      }}
                      secondaryAction={
                        <CardActions
                          isSelected={isSelected(attachment)}
                          onToggle={(added) =>
                            handleToggle(attachment, added, index)
                          }
                          onRemove={() =>
                            deleteAttachment.mutate(attachment.resourceId)
                          }
                        />
                      }
                    >
                      <ListItemAvatar>
                        {isImage(attachment) ? (
                          <Avatar
                            variant="rounded"
                            src={baseUrl + "/" + attachment.link}
                          />
                        ) : (
                          <Avatar variant="rounded">
                            {" "}
                            <SvgIcon
                              sx={{
                                margin: "10px",
                                fontSize: "70px",
                                color: "var(--primary_color_main)",
                              }}
                            >
                              {attachmentExtension(attachment) === "URL" ? (
                                <Link />
                              ) : (
                                <>
                                  <InsertDriveFileOutlined />
                                  <text
                                    x="12"
                                    y="14"
                                    width="15"
                                    textAnchor="middle"
                                    fontSize="4px"
                                    fontWeight="700"
                                    color="var(--primary_color_main)"
                                  >
                                    {attachmentExtension(
                                      attachment
                                    ).toUpperCase()}
                                  </text>
                                </>
                              )}
                            </SvgIcon>
                          </Avatar>
                        )}
                      </ListItemAvatar>

                      <ListItemText primary={attachment?.name}></ListItemText>
                    </ListItem>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          CONCLUIR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  attachments: state.attachment.attachmentList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ listAll, setAttachment, removeAttachment }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentList);
