import { Button, Typography } from "@mui/material";
import Question from "../../../../EditCourse/Evaluation/Question";
import { useEffect, useState } from "react";

export default function Linear({
  evaluation,
  onSubmit,
  timeEnded,
  timeRunning,
}) {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (evaluation?.questions?.length > 0) {
      setAnswers(evaluation.questions.map(() => null));
    }
  }, [evaluation]);

  useEffect(() => {
    if (timeEnded && timeRunning) {
      onSubmit(answers);
    }
  }, [timeEnded, timeRunning]);

  return (
    <>
      {evaluation?.questions?.length > 0 &&
        evaluation.questions.map((question, index) => (
          <Question
            key={`question-${index}`}
            value={answers?.[index]}
            onChange={(event) => {
              const newValue = event?.target?.value ?? event;

              setAnswers((prev) => {
                const newAnswers = [...prev];
                newAnswers[index] = newValue;
                return newAnswers;
              });
            }}
            question={question}
            index={index}
            evaluation={evaluation}
            exhibition
          />
        ))}
      <Button
        sx={{ margin: "auto" }}
        variant="contained"
        color="primary"
        disabled={
          timeEnded ||
          answers.some((answer) => {
            return answer == null;
          })
        }
        onClick={() => onSubmit(answers)}
      >
        <Typography>
          {timeEnded ? "O tempo acabou" : "Enviar respostas"}
        </Typography>
      </Button>
    </>
  );
}
