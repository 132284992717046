import { Container } from "@mui/material";
import DocContent from "./DocContent";
import DocInput from "./DocInput";

function DocComponent({
  contentId,
  mandatory,
  value,
  onChange,
  isEditing = false,
}) {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      {isEditing && <DocInput initialValue={value} onChange={onChange} />}
      <DocContent value={value} contentId={contentId} mandatory={mandatory} />
    </Container>
  );
}
export default DocComponent;
