import axios from "axios";
import { courseApi } from "../../../../../shared/api/courseApi";
import { getClient } from "../../../../../shared/config/urlConfig";

const domain = getClient();

function useModuleListAPI(chapterId) {
  if (!chapterId) return [() => {}];

  async function fetchList() {
    const request = axios.get(courseApi.chapter_all_content_types.url, {
      params: {
        chapterId,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        domain,
      },
    });

    const resp = await request;

    if (resp.status >= 200 && resp.status < 300) {
      const list = resp.data;
      return list;
    } else {
      return [];
    }
  }

  return [fetchList];
}

export default useModuleListAPI;
