import { useState } from "react";

function useContentListManager() {
  const [incrementalId, setIncrementalId] = useState(0);
  const [list, setList] = useState([]);

  function getId() {
    setIncrementalId((prev) => prev + 1);
    return incrementalId;
  }

  function insertList(list) {
    setList(list);
  }

  function insertNewContent({ chapterContentId, mode, ...module }) {
    const newId = chapterContentId ?? getId();

    setList((prevModules) => {
      const list = [...prevModules];
      list.push({
        chapterContentId: newId,
        isIdValid: !!chapterContentId,
        mode: mode ?? "edit",
        ...module,
      });
      return list;
    });

    return newId;
  }

  function deleteContent(moduleId) {
    setList((prevContentList) => {
      const list = [...prevContentList];
      list.splice(getContentIndexById(moduleId, list), 1);
      return list;
    });
  }

  function getContentByIndex(moduleId) {
    const module = list.find((module) => moduleId === module.chapterContentId);
    return module;
  }

  function getContentIndexById(moduleId) {
    const moduleIndex = list.findIndex(
      (module) => moduleId === module.chapterContentId
    );
    return moduleIndex;
  }

  function changeContentIndex(movedModuleIndex, newIndex) {
    setList((prevState) => {
      const currState = [...prevState];
      const movedModule = currState.splice(movedModuleIndex, 1)[0];
      currState.splice(newIndex, 0, movedModule);
      return currState;
    });
  }

  function toListBottom(movedModuleIndex) {
    changeContentIndex(movedModuleIndex, list.length - 1);
  }

  function insertBeforeContent(staticModuleIndex, movedModuleIndex) {
    changeContentIndex(movedModuleIndex, staticModuleIndex);
  }

  function insertAfterContent(staticModuleIndex, movedModuleIndex) {
    changeContentIndex(movedModuleIndex, staticModuleIndex + 1);
  }

  function editContent(itemId, editObj) {
    setList((previous) => {
      const itemIndex = previous.findIndex(
        ({ chapterContentId }) => itemId === chapterContentId
      );

      if (itemIndex !== -1) {
        previous[itemIndex] = { ...previous[itemIndex], ...editObj };
      }

      return [...previous];
    });
  }

  function clearList() {
    setList(() => []);
  }

  const methods = {
    insertList,
    newContent: insertNewContent,
    deleteContent,
    getContent: getContentByIndex,
    getContentIndex: getContentIndexById,
    insertBeforeContent,
    insertAfterContent,
    toListBottom,
    editContent,
    clearList,
  };

  return [list, methods];
}

export default useContentListManager;
