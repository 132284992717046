import { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import Scorm from "../../../../../ScormPage/scorm/Scorm";

function ScormContent({ chapter, capituloid, matriculaid, ...props }) {
  const [open, setOpen] = useState(false);

  const closeScorm = () => {
    setOpen(false);
  };
  const openScorm = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(true);
  }, [capituloid]);

  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton
        sx={{
          display: !open && "none",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 5,
          backgroundColor: "white",
        }}
        onClick={closeScorm}
      >
        <CloseIcon color="error" />
      </IconButton>
      <Scorm
        chapter={chapter}
        capituloid={capituloid}
        matriculaid={matriculaid}
        hidden={!open}
      />

      <Button
        sx={{ display: "block", margin: "auto" }}
        color="primary"
        variant="contained"
        onClick={openScorm}
      >
        Abrir conteúdo
      </Button>
    </Paper>
  );
}

export default ScormContent;
