import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getAttachments = async (type) => {
  const res = await axios.cadernoVirtualBase(
    "https://api.cadernovirtual.com.br/courses-service/resource",
    {
      params: {
        professorId: _cookies.getItem("auth").user?.profile?.isProfessor?.ID,
        type,
      },
    }
  );

  return res.object;
};

export const useAttachments = ({ config, type } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["userAttachments", type],
    queryFn: () => getAttachments(type),
  });
};
